import React from 'react'

function SortbyIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.6501 16C5.1701 16 4.8501 15.68 4.8501 15.2V0.8C4.8501 0.32 5.1701 0 5.6501 0C6.1301 0 6.4501 0.32 6.4501 0.8V15.2C6.4501 15.68 6.1301 16 5.6501 16Z" fill="currentColor" />
      <path d="M0.850049 6.4C0.610049 6.4 0.450049 6.32 0.290049 6.16C-0.0299512 5.84 -0.0299512 5.36 0.290049 5.04L5.09005 0.24C5.41005 -0.08 5.89005 -0.08 6.21005 0.24C6.53005 0.56 6.53005 1.04 6.21005 1.36L1.41005 6.16C1.25005 6.32 1.09005 6.4 0.850049 6.4Z" fill="currentColor" />
      <path d="M9.6501 16C9.1701 16 8.8501 15.68 8.8501 15.2V0.8C8.8501 0.32 9.1701 0 9.6501 0C10.1301 0 10.4501 0.32 10.4501 0.8V15.2C10.4501 15.68 10.1301 16 9.6501 16Z" fill="currentColor" />
      <path d="M9.6501 15.9999C9.4101 15.9999 9.2501 15.9199 9.0901 15.7599C8.7701 15.4399 8.7701 14.9599 9.0901 14.6399L13.8901 9.83985C14.2101 9.51985 14.6901 9.51985 15.0101 9.83985C15.3301 10.1599 15.3301 10.6399 15.0101 10.9599L10.2101 15.7599C10.0501 15.9999 9.8901 15.9999 9.6501 15.9999Z" fill="currentColor" />
    </svg>
  )
}

export default SortbyIcon