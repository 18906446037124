import React from "react"
import { SvgIcon, type SvgIconProps } from "@mui/material"

function CalenderIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.5 3H18C18 2.173 17.327 1.5 16.5 1.5C15.673 1.5 15 2.173 15 3H9C9 2.173 8.327 1.5 7.5 1.5C6.673 1.5 6 2.173 6 3H4.5C3.1215 3 2 4.1215 2 5.5V20C2 21.3785 3.1215 22.5 4.5 22.5H19.5C20.8785 22.5 22 21.3785 22 20V5.5C22 4.1215 20.8785 3 19.5 3ZM16 3C16.0058 2.87126 16.061 2.7497 16.1541 2.66064C16.2472 2.57157 16.3711 2.52187 16.5 2.52187C16.6289 2.52187 16.7528 2.57157 16.8459 2.66064C16.939 2.7497 16.9942 2.87126 17 3V4C16.9942 4.12874 16.939 4.2503 16.8459 4.33936C16.7528 4.42843 16.6289 4.47813 16.5 4.47813C16.3711 4.47813 16.2472 4.42843 16.1541 4.33936C16.061 4.2503 16.0058 4.12874 16 4V3ZM7 3C7.00576 2.87126 7.06095 2.7497 7.15409 2.66064C7.24723 2.57157 7.37113 2.52187 7.5 2.52187C7.62887 2.52187 7.75277 2.57157 7.84591 2.66064C7.93905 2.7497 7.99424 2.87126 8 3V4C7.99424 4.12874 7.93905 4.2503 7.84591 4.33936C7.75277 4.42843 7.62887 4.47813 7.5 4.47813C7.37113 4.47813 7.24723 4.42843 7.15409 4.33936C7.06095 4.2503 7.00576 4.12874 7 4V3ZM3 5.5C3 4.673 3.673 4 4.5 4H6C6 4.827 6.673 5.5 7.5 5.5C8.327 5.5 9 4.827 9 4H15C15 4.827 15.673 5.5 16.5 5.5C17.327 5.5 18 4.827 18 4H19.5C20.327 4 21 4.673 21 5.5V7.5H3V5.5ZM21 20C21 20.827 20.327 21.5 19.5 21.5H4.5C3.673 21.5 3 20.827 3 20V8.5H21V20Z" fill="#1C3049" />
        <path d="M6 14H7.5C8.0515 14 8.5 13.5515 8.5 13V11.5C8.5 10.9485 8.0515 10.5 7.5 10.5H6C5.4485 10.5 5 10.9485 5 11.5V13C5 13.5515 5.4485 14 6 14ZM6 11.5H7.5L7.501 13H6V11.5ZM11.25 14H12.75C13.3015 14 13.75 13.5515 13.75 13V11.5C13.75 10.9485 13.3015 10.5 12.75 10.5H11.25C10.6985 10.5 10.25 10.9485 10.25 11.5V13C10.25 13.5515 10.6985 14 11.25 14ZM11.25 11.5H12.75L12.751 13H11.25V11.5ZM16.5 14H18C18.5515 14 19 13.5515 19 13V11.5C19 10.9485 18.5515 10.5 18 10.5H16.5C15.9485 10.5 15.5 10.9485 15.5 11.5V13C15.5 13.5515 15.9485 14 16.5 14ZM16.5 11.5H18L18.001 13H16.5V11.5ZM6 19.5H7.5C8.0515 19.5 8.5 19.0515 8.5 18.5V17C8.5 16.4485 8.0515 16 7.5 16H6C5.4485 16 5 16.4485 5 17V18.5C5 19.0515 5.4485 19.5 6 19.5ZM6 17H7.5L7.501 18.5H6V17ZM11.25 19.5H12.75C13.3015 19.5 13.75 19.0515 13.75 18.5V17C13.75 16.4485 13.3015 16 12.75 16H11.25C10.6985 16 10.25 16.4485 10.25 17V18.5C10.25 19.0515 10.6985 19.5 11.25 19.5ZM11.25 17H12.75L12.751 18.5H11.25V17ZM16.5 19.5H18C18.5515 19.5 19 19.0515 19 18.5V17C19 16.4485 18.5515 16 18 16H16.5C15.9485 16 15.5 16.4485 15.5 17V18.5C15.5 19.0515 15.9485 19.5 16.5 19.5ZM16.5 17H18L18.001 18.5H16.5V17Z" fill="#1C3049" />
      </svg>
    </SvgIcon>
  )
}

export default CalenderIcon