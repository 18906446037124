import React from "react"
import { SvgIcon, type SvgIconProps } from "@mui/material"

function OutlinedCheckIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M13.33 2.5265C11.7184 1.80841 9.91785 1.63052 8.19688 2.01935C6.47592 2.40818 4.92674 3.3429 3.78041 4.68411C2.63408 6.02531 1.95201 7.70115 1.83592 9.46167C1.71983 11.2222 2.17595 12.9731 3.13624 14.4532C4.09654 15.9333 5.50956 17.0634 7.16457 17.6748C8.81958 18.2862 10.6279 18.3463 12.3198 17.8461C14.0118 17.3458 15.4967 16.312 16.5531 14.8989C17.6095 13.4858 18.1808 11.769 18.1818 10.0047V9.16884C18.1818 8.66676 18.5888 8.25975 19.0909 8.25975C19.593 8.25975 20 8.66676 20 9.16884V10.0052C19.9988 12.1616 19.3005 14.2604 18.0093 15.9875C16.7182 17.7147 14.9033 18.9782 12.8354 19.5896C10.7674 20.201 8.55726 20.1276 6.53447 19.3803C4.51168 18.633 2.78465 17.2518 1.61096 15.4428C0.437266 13.6338 -0.120209 11.4938 0.0216768 9.34204C0.163562 7.19029 0.997207 5.14205 2.39828 3.5028C3.79935 1.86354 5.69278 0.721106 7.79619 0.24587C9.89959 -0.229366 12.1003 -0.0119393 14.07 0.865724C14.5286 1.07007 14.7347 1.6075 14.5304 2.06611C14.326 2.52472 13.7886 2.73085 13.33 2.5265Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M19.7077 2.09724C20.0629 2.45209 20.0632 3.02769 19.7083 3.38289L10.6174 12.4829C10.4469 12.6535 10.2157 12.7494 9.97448 12.7495C9.7333 12.7495 9.50197 12.6538 9.33143 12.4832L6.60416 9.75594C6.24914 9.40092 6.24914 8.82531 6.60416 8.47029C6.95918 8.11527 7.53478 8.11527 7.88981 8.47029L9.97393 10.5544L18.422 2.09789C18.7769 1.74269 19.3525 1.7424 19.7077 2.09724Z" fill="currentColor" />
    </svg>
    </SvgIcon>
  )
}

export default OutlinedCheckIcon
