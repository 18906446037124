import React from 'react'

function Island() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.4404 2.47758C14.8427 0.879883 12.7185 0 10.459 0C8.19958 0 6.07528 0.879883 4.47762 2.47758C2.87993 4.07531 2.00005 6.19953 2.00005 8.45895C2.00005 13.0297 6.32184 16.8315 8.64368 18.8739C8.96633 19.1577 9.24497 19.4029 9.46696 19.6102C9.74508 19.87 10.1021 19.9999 10.459 19.9999C10.816 19.9999 11.1729 19.87 11.4511 19.6102C11.6731 19.4028 11.9517 19.1577 12.2743 18.8739C14.5962 16.8314 18.918 13.0297 18.918 8.45895C18.9179 6.19953 18.0381 4.07531 16.4404 2.47758ZM11.5005 17.9943C11.1708 18.2843 10.8861 18.5348 10.6513 18.7541C10.5434 18.8548 10.3745 18.8548 10.2666 18.7541C10.0319 18.5348 9.74715 18.2843 9.41743 17.9943C7.23462 16.0741 3.17153 12.5 3.17153 8.45898C3.17153 4.4407 6.44063 1.1716 10.459 1.1716C14.4772 1.1716 17.7463 4.4407 17.7463 8.45898C17.7464 12.5 13.6833 16.0741 11.5005 17.9943Z" fill="white" />
            <path d="M10.6668 4.41162C8.61128 4.41162 6.93901 6.08385 6.93901 8.13936C6.93901 10.1949 8.61128 11.8671 10.6668 11.8671C12.7223 11.8671 14.3945 10.1949 14.3945 8.13936C14.3945 6.08385 12.7223 4.41162 10.6668 4.41162ZM10.6668 10.6955C9.25729 10.6955 8.11057 9.54877 8.11057 8.13932C8.11057 6.72986 9.25729 5.58314 10.6668 5.58314C12.0763 5.58314 13.223 6.72986 13.223 8.13932C13.223 9.54877 12.0763 10.6955 10.6668 10.6955Z" fill="white" />
        </svg>
    )
}

export default Island



