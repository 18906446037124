import React from 'react'

function BullionmarkSwiperDash() {
    return (
        <svg width="11" height="1" viewBox="0 0 11 1" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line y1="0.5" x2="11" y2="0.5" stroke="black" />
        </svg>
    )
}

export default BullionmarkSwiperDash