import React from 'react'
import { SvgIcon, type SvgIconProps } from "@mui/material"

function CrossIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <path d="M15.5203 2.60649L10.0795 7.97148L15.5203 13.3365C15.6717 13.4782 15.7924 13.6496 15.8749 13.8399C15.9574 14.0302 16 14.2354 16 14.4428C16 14.6502 15.9574 14.8554 15.8749 15.0457C15.7924 15.236 15.6717 15.4074 15.5203 15.5491C15.3786 15.6896 15.2107 15.8007 15.026 15.8761C14.8414 15.9516 14.6437 15.9898 14.4442 15.9886C14.0466 15.987 13.6656 15.8291 13.3834 15.5491L7.94259 10.1084L2.57761 15.5491C2.29532 15.8291 1.91432 15.987 1.51673 15.9886C1.31728 15.9898 1.11956 15.9516 0.934911 15.8761C0.750262 15.8007 0.582316 15.6896 0.440704 15.5491C0.158435 15.2652 0 14.8811 0 14.4807C0 14.0803 0.158435 13.6962 0.440704 13.4122L5.80569 7.97148L0.440704 2.60649C0.19242 2.31657 0.0626806 1.94363 0.0774133 1.56221C0.0921461 1.18079 0.250267 0.81897 0.520175 0.549061C0.790083 0.279153 1.1519 0.121034 1.53332 0.106301C1.91475 0.0915682 2.28768 0.221308 2.57761 0.469592L7.94259 5.83458L13.3076 0.469592C13.4479 0.324306 13.6154 0.208078 13.8007 0.127545C13.9859 0.0470127 14.1852 0.00375163 14.3871 0.000233371C14.5891 -0.00328489 14.7897 0.0330079 14.9777 0.10704C15.1656 0.181071 15.3371 0.291391 15.4824 0.431702C15.6277 0.572013 15.7439 0.739568 15.8244 0.924797C15.9049 1.11003 15.9482 1.3093 15.9517 1.51125C15.9552 1.7132 15.9189 1.91386 15.8449 2.10178C15.7709 2.28971 15.6606 2.46121 15.5203 2.60649Z" fill="currentColor" />
      </g>
    </SvgIcon>
  )
}

export default CrossIcon