import React from 'react'
import { SvgIcon, type SvgIconProps } from '@mui/material'


function GoldBarIcon(props: SvgIconProps) {
    return (
        <SvgIcon width="50" height="51" viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.1" y="0.937744" width="50" height="50" rx="10" fill="#EAA22B" />
            <path d="M33.5377 19.8784H34.0332V20.3738C34.0332 20.6366 34.1375 20.8886 34.3234 21.0745C34.5092 21.2603 34.7612 21.3647 35.024 21.3647C35.2868 21.3647 35.5388 21.2603 35.7246 21.0745C35.9105 20.8886 36.0149 20.6366 36.0149 20.3738V19.8784H36.5103C36.7731 19.8784 37.0251 19.774 37.2109 19.5882C37.3968 19.4024 37.5011 19.1503 37.5011 18.8875C37.5011 18.6247 37.3968 18.3727 37.2109 18.1869C37.0251 18.0011 36.7731 17.8967 36.5103 17.8967H36.0149V17.4013C36.0149 17.1385 35.9105 16.8864 35.7246 16.7006C35.5388 16.5148 35.2868 16.4104 35.024 16.4104C34.7612 16.4104 34.5092 16.5148 34.3234 16.7006C34.1375 16.8864 34.0332 17.1385 34.0332 17.4013V17.8967H33.5377C33.2749 17.8967 33.0229 18.0011 32.8371 18.1869C32.6513 18.3727 32.5469 18.6247 32.5469 18.8875C32.5469 19.1503 32.6513 19.4024 32.8371 19.5882C33.0229 19.774 33.2749 19.8784 33.5377 19.8784Z" fill="#EAA22B" />
            <path d="M22.3258 16.3595C22.8805 16.5258 23.3852 16.8271 23.7947 17.2366C24.2042 17.6461 24.5056 18.1508 24.6718 18.7055C24.7376 18.9028 24.8638 19.0743 25.0325 19.1959C25.2012 19.3175 25.4039 19.3829 25.6119 19.3829C25.8198 19.3829 26.0225 19.3175 26.1912 19.1959C26.3599 19.0743 26.4861 18.9028 26.5519 18.7055C26.7181 18.1508 27.0195 17.6461 27.429 17.2366C27.8385 16.8271 28.3432 16.5258 28.8979 16.3595C29.0952 16.2937 29.2667 16.1675 29.3883 15.9988C29.5099 15.8301 29.5753 15.6274 29.5753 15.4195C29.5753 15.2115 29.5099 15.0089 29.3883 14.8401C29.2667 14.6714 29.0952 14.5452 28.8979 14.4794C28.3432 14.3132 27.8385 14.0118 27.429 13.6023C27.0195 13.1928 26.7181 12.6881 26.5519 12.1334C26.4861 11.9361 26.3599 11.7646 26.1912 11.643C26.0225 11.5215 25.8198 11.4561 25.6119 11.4561C25.4039 11.4561 25.2012 11.5215 25.0325 11.643C24.8638 11.7646 24.7376 11.9361 24.6718 12.1334C24.5056 12.6881 24.2042 13.1928 23.7947 13.6023C23.3852 14.0118 22.8805 14.3132 22.3258 14.4794C22.1285 14.5452 21.957 14.6714 21.8354 14.8401C21.7138 15.0089 21.6484 15.2115 21.6484 15.4195C21.6484 15.6274 21.7138 15.8301 21.8354 15.9988C21.957 16.1675 22.1285 16.2937 22.3258 16.3595Z" fill="#EAA22B" />
            <path d="M14.4 19.332C14.7214 19.4261 15.014 19.5995 15.2508 19.8364C15.4877 20.0732 15.6611 20.3658 15.7552 20.6872C15.821 20.8845 15.9472 21.056 16.1159 21.1776C16.2846 21.2991 16.4873 21.3646 16.6952 21.3646C16.9032 21.3646 17.1058 21.2991 17.2745 21.1776C17.4433 21.056 17.5695 20.8845 17.6353 20.6872C17.7293 20.3658 17.9028 20.0732 18.1396 19.8364C18.3764 19.5995 18.669 19.4261 18.9904 19.332C19.1877 19.2662 19.3592 19.14 19.4808 18.9713C19.6024 18.8026 19.6678 18.5999 19.6678 18.392C19.6678 18.1841 19.6024 17.9814 19.4808 17.8127C19.3592 17.644 19.1877 17.5178 18.9904 17.4519C18.669 17.3579 18.3764 17.1844 18.1396 16.9476C17.9028 16.7108 17.7293 16.4182 17.6353 16.0968C17.5695 15.8995 17.4433 15.728 17.2745 15.6064C17.1058 15.4848 16.9032 15.4194 16.6952 15.4194C16.4873 15.4194 16.2846 15.4848 16.1159 15.6064C15.9472 15.728 15.821 15.8995 15.7552 16.0968C15.6611 16.4182 15.4877 16.7108 15.2508 16.9476C15.014 17.1844 14.7214 17.3579 14.4 17.4519C14.2027 17.5178 14.0312 17.644 13.9096 17.8127C13.7881 17.9814 13.7227 18.1841 13.7227 18.392C13.7227 18.5999 13.7881 18.8026 13.9096 18.9713C14.0312 19.14 14.2027 19.2662 14.4 19.332Z" fill="#EAA22B" />
            <path d="M38.4544 35.9549L36.4727 29.0189C36.4135 28.8119 36.2885 28.6299 36.1166 28.5002C35.9447 28.3706 35.7353 28.3005 35.52 28.3005H34.2857L32.5093 22.083C32.4501 21.876 32.3251 21.6939 32.1532 21.5643C31.9813 21.4346 31.7719 21.3645 31.5566 21.3645H19.6664C19.4511 21.3645 19.2417 21.4346 19.0698 21.5643C18.8979 21.6939 18.7729 21.876 18.7137 22.083L16.9373 28.3005H15.703C15.4877 28.3005 15.2782 28.3706 15.1064 28.5002C14.9345 28.6299 14.8095 28.8119 14.7503 29.0189L12.7686 35.9549C12.7265 36.1024 12.7191 36.2576 12.7472 36.4084C12.7752 36.5592 12.8379 36.7014 12.9302 36.8239C13.0226 36.9463 13.1421 37.0457 13.2793 37.1141C13.4166 37.1825 13.5679 37.2181 13.7213 37.2182H37.5017C37.6551 37.2181 37.8064 37.1825 37.9436 37.1141C38.0809 37.0457 38.2004 36.9463 38.2928 36.8239C38.3851 36.7014 38.4478 36.5592 38.4758 36.4084C38.5039 36.2576 38.4965 36.1024 38.4544 35.9549ZM20.4139 23.3462H22.8823L24.2979 28.3005H18.9982L20.4139 23.3462ZM15.0348 35.2365L16.4504 30.2822H18.9189L20.3345 35.2365H15.0348ZM22.9616 35.2365L24.3773 30.2822H26.8457L28.2614 35.2365H22.9616Z" fill="#EAA22B" />
        </SvgIcon>
    )
}

export default GoldBarIcon





