import React from "react"
import { SvgIcon, type SvgIconProps } from "@mui/material"

function SignInIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M15.467 12.3881C16.5062 11.6523 17.2845 10.605 17.6891 9.39764C18.0937 8.19027 18.1037 6.88547 17.7177 5.67204C17.3316 4.4586 16.5695 3.39945 15.5417 2.64783C14.5138 1.89621 13.2734 1.49109 12.0001 1.49109C10.7267 1.49109 9.48636 1.89621 8.45849 2.64783C7.43061 3.39945 6.66852 4.4586 6.28247 5.67204C5.89641 6.88547 5.90642 8.19027 6.31104 9.39764C6.71567 10.605 7.49392 11.6523 8.5332 12.3881C6.5065 12.9787 4.72576 14.2102 3.45779 15.898C2.18983 17.5858 1.50292 19.639 1.5 21.75C1.5 21.9489 1.57902 22.1397 1.71967 22.2804C1.86032 22.421 2.05109 22.5 2.25 22.5H21.75C21.9489 22.5 22.1397 22.421 22.2803 22.2804C22.421 22.1397 22.5 21.9489 22.5 21.75C22.4971 19.639 21.8102 17.5858 20.5423 15.898C19.2743 14.2102 17.4936 12.9788 15.467 12.3881ZM7.5 7.50002C7.5 6.61 7.76392 5.73997 8.25839 4.99995C8.75285 4.25993 9.45566 3.68316 10.2779 3.34256C11.1002 3.00197 12.005 2.91285 12.8779 3.08648C13.7508 3.26012 14.5526 3.6887 15.182 4.31804C15.8113 4.94737 16.2399 5.7492 16.4135 6.62211C16.5872 7.49503 16.4981 8.39983 16.1575 9.22209C15.8169 10.0444 15.2401 10.7472 14.5001 11.2416C13.76 11.7361 12.89 12 12 12C10.8069 11.9987 9.66314 11.5241 8.81952 10.6805C7.9759 9.83688 7.50136 8.69308 7.5 7.50002ZM3.03375 21C3.22329 18.9512 4.17057 17.0469 5.69021 15.6597C7.20985 14.2726 9.19245 13.5024 11.25 13.5H12.75C14.8076 13.5024 16.7902 14.2726 18.3098 15.6597C19.8294 17.0469 20.7767 18.9512 20.9662 21H3.03375Z" fill="currentColor" />
    </SvgIcon>
  )
}

export default SignInIcon
