import React from 'react'
import { SvgIcon, type SvgIconProps } from "@mui/material"

function TickIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="79" height="79" viewBox="0 0 79 79" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M39.5002 0C17.6856 0 0 17.6794 0 39.5002C0 61.321 17.6856 79.0004 39.5002 79.0004C61.3148 79.0004 79.0004 61.3102 79.0004 39.5002C79.0004 17.6902 61.3148 0 39.5002 0Z" fill="#2AD352" />
        <path d="M3.66828e-05 39.5003C-0.00888006 46.0293 1.60793 52.4578 4.70457 58.2058C8.64978 59.3641 12.7407 59.9508 16.8524 59.9478C40.5989 59.9478 59.849 40.6976 59.849 16.9512C59.8526 12.7743 59.2475 8.61912 58.053 4.61664C52.3427 1.575 45.9701 -0.0107196 39.5003 5.45382e-05C17.6856 5.45382e-05 3.66828e-05 17.6795 3.66828e-05 39.5003Z" fill="#74DA7F" />
        <path d="M62.0278 32.9549L38.2861 57.8879C37.639 58.5667 36.8617 59.1078 36.0005 59.479C35.1394 59.8502 34.2122 60.0438 33.2745 60.0481H33.2436C32.3111 60.0483 31.388 59.8612 30.5293 59.4978C29.6705 59.1344 28.8935 58.6022 28.2444 57.9326L15.6459 44.9562C14.995 44.3028 14.4803 43.5266 14.1318 42.6727C13.7832 41.8188 13.6077 40.9041 13.6155 39.9818C13.6233 39.0596 13.8141 38.148 14.177 37.3001C14.5399 36.4521 15.0676 35.6847 15.7295 35.0424C16.3913 34.4 17.1742 33.8955 18.0326 33.5582C18.891 33.2208 19.8079 33.0573 20.73 33.0771C21.6521 33.097 22.5611 33.2997 23.4042 33.6737C24.2473 34.0476 25.0077 34.5853 25.6414 35.2555L33.1942 43.0352L51.9399 23.3499C52.5678 22.6766 53.323 22.1344 54.1619 21.7548C55.0007 21.3752 55.9064 21.1657 56.8268 21.1383C57.7471 21.111 58.6637 21.2664 59.5235 21.5956C60.3834 21.9247 61.1695 22.4211 61.8363 23.056C62.5031 23.6909 63.0374 24.4517 63.4083 25.2944C63.7792 26.1371 63.9794 27.045 63.9972 27.9655C64.015 28.886 63.8501 29.801 63.5121 30.6574C63.1741 31.5138 62.6696 32.2947 62.0278 32.9549Z" fill="white" />
      </svg>
    </SvgIcon>
  )
}

export default TickIcon