import React from 'react'
import { SvgIcon, type SvgIconProps } from '@mui/material'


function GreenConfirmationIcon(props: SvgIconProps) {
    return (
        <SvgIcon width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <rect width="30" height="30" rx="15" fill="#008001" />
            <path d="M14.5156 22.2C14.4672 22.2 14.4193 22.1899 14.3749 22.1705C14.3306 22.1511 14.2907 22.1227 14.2579 22.0872L7.3081 14.5695C7.26177 14.5194 7.23106 14.4569 7.21972 14.3896C7.20838 14.3223 7.21691 14.2531 7.24426 14.1906C7.27161 14.1281 7.3166 14.0749 7.37372 14.0375C7.43084 14.0001 7.49761 13.9802 7.56586 13.9802H10.9111C10.9613 13.9802 11.011 13.991 11.0567 14.0119C11.1024 14.0327 11.1431 14.0631 11.176 14.101L13.4987 16.7731C13.7497 16.2365 14.2356 15.3431 15.0883 14.2544C16.3489 12.645 18.6936 10.278 22.7052 8.14129C22.7828 8.1 22.873 8.08928 22.958 8.11126C23.0431 8.13323 23.1168 8.1863 23.1647 8.25998C23.2125 8.33365 23.231 8.42259 23.2164 8.50923C23.2019 8.59586 23.1554 8.67389 23.0861 8.72792C23.0708 8.73989 21.5241 9.95793 19.744 12.189C18.1057 14.2421 15.9279 17.5993 14.8562 21.9333C14.8374 22.0095 14.7936 22.0771 14.7319 22.1255C14.6701 22.1738 14.5939 22.2001 14.5155 22.2001L14.5156 22.2Z" fill="white" />
        </SvgIcon>
    )
}

export default GreenConfirmationIcon



