import React from 'react'

function ContainedCheckIcon() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="14" height="14" rx="7" fill="#008001" />
      <path d="M6.4243 10.4999C6.40026 10.4999 6.37649 10.495 6.35446 10.4853C6.33244 10.4757 6.31265 10.4616 6.29634 10.4439L2.8461 6.71178C2.8231 6.6869 2.80786 6.65585 2.80223 6.62244C2.7966 6.58902 2.80083 6.55469 2.81441 6.52365C2.82799 6.49261 2.85032 6.4662 2.87868 6.44765C2.90703 6.4291 2.94018 6.41922 2.97407 6.41922H4.63482C4.65975 6.41922 4.6844 6.42458 4.70709 6.43491C4.72978 6.44525 4.74999 6.46034 4.76635 6.47915L5.91944 7.80573C6.04405 7.53935 6.28529 7.09581 6.70862 6.55534C7.33444 5.75633 8.4985 4.58124 10.4901 3.52045C10.5286 3.49995 10.5734 3.49463 10.6156 3.50554C10.6578 3.51645 10.6944 3.54279 10.7181 3.57937C10.7419 3.61595 10.7511 3.6601 10.7438 3.70311C10.7366 3.74612 10.7135 3.78486 10.6792 3.81168C10.6716 3.81763 9.90367 4.42233 9.01994 5.52994C8.2066 6.54922 7.12542 8.2159 6.59341 10.3676C6.58406 10.4054 6.56233 10.4389 6.53167 10.4629C6.50101 10.487 6.46319 10.5 6.42425 10.5L6.4243 10.4999Z" fill="white" />
    </svg>
  )
}

export default ContainedCheckIcon