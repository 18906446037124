import React from "react"
import { SvgIcon, type SvgIconProps } from "@mui/material"

function Shield1Icon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_246_16)">
        <path d="M22.2222 3.56712L22.1011 3.24884L21.7802 3.0807C18.4436 1.32716 13.8628 0.0720631 11.6788 0.0030027L11.5544 0L11.4332 0.024021C9.89098 0.333292 4.6651 1.46528 2.09145 3.10772L1.8164 3.28187L1.7149 3.57313C-0.354501 9.55736 1.82295 18.4121 11.4921 23.7208L12.0062 24L12.5105 23.7208C17.016 21.2226 20.2543 17.7665 21.8752 13.725C23.2438 10.314 23.3683 6.61179 22.2222 3.56712ZM11.9997 21.8892C3.83341 17.154 1.85897 9.63543 3.49288 4.41987C5.36581 3.34793 8.90213 2.38108 11.7279 1.81058C13.6172 1.91267 17.6021 3.03566 20.4803 4.47692C22.828 11.3019 18.0998 18.277 11.9997 21.8892Z" fill="currentColor" />
        <path d="M11.6135 13.2197C11.6057 13.2197 11.5979 13.2197 11.5902 13.2197C11.375 13.2115 11.1728 13.114 11.0302 12.946L8.76967 10.2667C8.48451 9.93081 8.51562 9.41608 8.83967 9.12078C9.16112 8.82549 9.65367 8.85529 9.93624 9.19393L11.6472 11.2231L15.248 7.45739C15.5513 7.14042 16.0439 7.14042 16.3472 7.45739C16.6505 7.77436 16.6505 8.28909 16.3472 8.60605L12.1657 12.9813C12.0179 13.133 11.8209 13.2197 11.6135 13.2197Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_246_16">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

export default Shield1Icon