import React from 'react'

function OfferTagIcon() {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_455_64034)">
        <g clipPath="url(#clip1_455_64034)">
          <path d="M16.0629 5.93703C15.8076 5.6818 15.4066 5.6818 15.1514 5.93703C14.8962 6.19226 14.8962 6.59325 15.1514 6.84853C15.4066 7.10376 15.8076 7.10376 16.0629 6.84853C16.3181 6.59329 16.3181 6.19226 16.0629 5.93703Z" fill="#EAA22B" />
          <path d="M9.68201 8.67141C9.42677 8.41617 9.02574 8.41617 8.77051 8.67141C8.51532 8.9266 8.51528 9.32767 8.77051 9.5829C9.02574 9.83818 9.42681 9.83809 9.68201 9.5829C9.93724 9.32767 9.93724 8.92664 9.68201 8.67141Z" fill="#EAA22B" />
          <path d="M10.5939 15.0521C10.3386 14.7968 9.93761 14.7968 9.68238 15.0521C9.42719 15.3072 9.42714 15.7083 9.68238 15.9635C9.93761 16.2188 10.3387 16.2187 10.5939 15.9635C10.8491 15.7083 10.8491 15.3073 10.5939 15.0521Z" fill="#EAA22B" />
          <path d="M21.5228 9.17286L20.0645 3.33922C20.028 3.21161 19.9825 3.0931 19.9277 2.98374L17.3482 5.56333C17.6855 6.28339 17.567 7.16752 16.9745 7.76001C16.218 8.51652 14.9965 8.51656 14.2399 7.76001C13.4834 7.00346 13.4834 5.78199 14.2399 5.02549C14.8324 4.43299 15.7166 4.31448 16.4366 4.65179L19.0162 2.07221C18.9069 2.01746 18.7883 1.97196 18.6607 1.93548L12.8271 0.477124C12.1709 0.31307 11.469 0.504452 10.9859 0.987549L0.567396 11.406C-0.18911 12.1625 -0.189153 13.384 0.567396 14.1406L7.85944 21.4326C8.61599 22.1891 9.83746 22.1891 10.594 21.4326L21.0124 11.0141C21.4955 10.5309 21.6869 9.82908 21.5228 9.17286ZM7.85944 7.76001C8.61599 7.00346 9.83737 7.00346 10.594 7.76001C11.3506 8.51656 11.3505 9.73795 10.594 10.4945C9.83746 11.251 8.61603 11.2511 7.85944 10.4945C7.10284 9.73799 7.10293 8.51652 7.85944 7.76001ZM11.5055 16.875C10.7489 17.6315 9.52748 17.6316 8.77093 16.875C8.01438 16.1185 8.01442 14.897 8.77093 14.1405C9.52748 13.384 10.7489 13.384 11.5055 14.1405C12.262 14.8971 12.262 16.1184 11.5055 16.875ZM13.8554 12.5027L5.65188 13.4142C5.44937 13.436 5.25841 13.3626 5.12448 13.2286C5.02479 13.1289 4.95669 12.9958 4.93976 12.8445C4.90104 12.4907 5.15563 12.172 5.50944 12.1324L13.713 11.2209C14.0663 11.1835 14.3855 11.4368 14.4251 11.7906C14.4638 12.1444 14.2093 12.4631 13.8554 12.5027Z" fill="#EAA22B" />
          <path d="M21.8089 0.191425C21.5537 -0.0638085 21.1527 -0.0638085 20.8974 0.191425L19.0166 2.07225C19.4268 2.26368 19.7458 2.58267 19.9281 2.98374L21.8089 1.10292C22.0642 0.847686 22.0642 0.446659 21.8089 0.191425Z" fill="#EAA22B" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_455_64034">
          <rect width="22" height="22" fill="white" />
        </clipPath>
        <clipPath id="clip1_455_64034">
          <rect width="22" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default OfferTagIcon