import React from "react"
import { SvgIcon } from "@mui/material"

function JointAccountIcon() {
    return (

        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 19H20C20.2652 19 20.5196 18.8946 20.7071 18.7071C20.8946 18.5196 21 18.2652 21 18V17C21 16.2044 20.6839 15.4413 20.1213 14.8787C19.5587 14.3161 18.7956 14 18 14H16M13.764 10C14.1691 10.4529 14.7021 10.7721 15.2925 10.9154C15.8829 11.0587 16.503 11.0193 17.0705 10.8025C17.6381 10.5857 18.1265 10.2017 18.471 9.70126C18.8156 9.20083 19.0001 8.60758 19.0001 8C19.0001 7.39243 18.8156 6.79917 18.471 6.29874C18.1265 5.7983 17.6381 5.41428 17.0705 5.19748C16.503 4.98069 15.8829 4.94134 15.2925 5.08464C14.7021 5.22794 14.1691 5.54714 13.764 6M3 18V17C3 16.2044 3.31607 15.4413 3.87868 14.8787C4.44129 14.3161 5.20435 14 6 14H10C10.7956 14 11.5587 14.3161 12.1213 14.8787C12.6839 15.4413 13 16.2044 13 17V18C13 18.2652 12.8946 18.5196 12.7071 18.7071C12.5196 18.8946 12.2652 19 12 19H4C3.73478 19 3.48043 18.8946 3.29289 18.7071C3.10536 18.5196 3 18.2652 3 18ZM11 8C11 8.79565 10.6839 9.55871 10.1213 10.1213C9.55871 10.6839 8.79565 11 8 11C7.20435 11 6.44129 10.6839 5.87868 10.1213C5.31607 9.55871 5 8.79565 5 8C5 7.20435 5.31607 6.44129 5.87868 5.87868C6.44129 5.31607 7.20435 5 8 5C8.79565 5 9.55871 5.31607 10.1213 5.87868C10.6839 6.44129 11 7.20435 11 8Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
        </svg>

    )
}

export default JointAccountIcon