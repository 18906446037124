import React from 'react'

function NewlLongArrowRight() {
    return (
        <svg width="24" height="8" viewBox="0 0 24 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.3536 4.35356C23.5488 4.15829 23.5488 3.84171 23.3536 3.64645L20.1716 0.464468C19.9763 0.269206 19.6597 0.269206 19.4645 0.464468C19.2692 0.65973 19.2692 0.976312 19.4645 1.17157L22.2929 4L19.4645 6.82843C19.2692 7.02369 19.2692 7.34027 19.4645 7.53554C19.6597 7.7308 19.9763 7.7308 20.1716 7.53554L23.3536 4.35356ZM-4.37114e-08 4.5L23 4.5L23 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z" fill="white" />
        </svg>
    )
}

export default NewlLongArrowRight