import React from 'react'

function BankIcon() {
  return (
    <svg width="47" height="48" viewBox="0 0 47 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.5" width="47" height="47" rx="23.5" fill="#3491FA" fill-opacity="0.1" />
      <path d="M33.4879 31.8827H13.5959C13.2127 32.0065 12.9355 32.2837 12.9355 32.6255V33.9462C12.9355 34.2881 13.2127 34.5652 13.5546 34.5652H33.4466C33.7885 34.5652 34.0657 34.2881 34.0657 33.9462V32.6255C34.0657 32.2837 33.7885 32.0065 33.4879 31.8827Z" fill="#3491FA" />
      <path d="M33.6839 17.6093L23.7379 13.4823C23.586 13.4193 23.4153 13.4193 23.2634 13.4823L13.3173 17.6093C13.0862 17.7052 12.9355 17.9308 12.9355 18.1811V19.4191C12.9355 19.761 13.2127 20.0382 13.5546 20.0382H33.4466C33.7885 20.0382 34.0657 19.761 34.0657 19.4191V18.1811C34.0657 17.9308 33.915 17.7052 33.6839 17.6093ZM27.2149 18.1811H19.7863C19.4445 18.1811 19.1673 17.9039 19.1673 17.562C19.1673 17.2201 19.4445 16.943 19.7863 16.943H27.2149C27.5568 16.943 27.8339 17.2201 27.8339 17.562C27.8339 17.9039 27.5568 18.1811 27.2149 18.1811Z" fill="#3491FA" />
      <path d="M14.1113 29.5302V30.7683H18.8574V29.5302C18.8574 29.1883 18.5802 28.9111 18.2383 28.9111H14.7304C14.3885 28.9111 14.1113 29.1883 14.1113 29.5302Z" fill="#3491FA" />
      <path d="M14.9375 21.2764H18.0327V27.6732H14.9375V21.2764Z" fill="#3491FA" />
      <path d="M28.9688 21.2764H32.064V27.6732H28.9688V21.2764Z" fill="#3491FA" />
      <path d="M28.1445 29.5302V30.7683H32.8906V29.5302C32.8906 29.1883 32.6134 28.9111 32.2715 28.9111H28.7636C28.4217 28.9111 28.1445 29.1883 28.1445 29.5302Z" fill="#3491FA" />
      <path d="M23.5017 27.8795C23.1603 27.8795 22.8826 27.6018 22.8826 27.2604C22.8826 26.9185 22.6055 26.6414 22.2636 26.6414C21.9217 26.6414 21.6445 26.9185 21.6445 27.2604C21.6445 28.0674 22.1621 28.7555 22.8826 29.0111V29.3239C22.8826 29.6658 23.1598 29.9429 23.5017 29.9429C23.8436 29.9429 24.1207 29.6658 24.1207 29.3239V29.0111C24.8412 28.7555 25.3588 28.0674 25.3588 27.2604C25.3588 26.2364 24.5257 25.4033 23.5017 25.4033C23.1603 25.4033 22.8826 25.1256 22.8826 24.7842C22.8826 24.4429 23.1603 24.1652 23.5017 24.1652C23.843 24.1652 24.1207 24.4429 24.1207 24.7842C24.1207 25.1261 24.3979 25.4033 24.7398 25.4033C25.0816 25.4033 25.3588 25.1261 25.3588 24.7842C25.3588 23.9772 24.8412 23.2891 24.1207 23.0336V22.7207C24.1207 22.3789 23.8436 22.1017 23.5017 22.1017C23.1598 22.1017 22.8826 22.3789 22.8826 22.7207V23.0336C22.1621 23.2891 21.6445 23.9772 21.6445 24.7842C21.6445 25.8082 22.4776 26.6414 23.5017 26.6414C23.843 26.6414 24.1207 26.9191 24.1207 27.2604C24.1207 27.6018 23.843 27.8795 23.5017 27.8795Z" fill="#3491FA" />
    </svg>
  )
}

export default BankIcon