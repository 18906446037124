import React from "react"
import { SvgIcon, type SvgIconProps } from "@mui/material"

function SmartMetalsIcon(props: SvgIconProps) {
  return (
    // <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <rect width="41" height="41" rx="10" fill="#008001" />
    //   <g clipPath="url(#clip0_267_54)">
    //     <path d="M18.211 24.4685L18.211 24.4685L19.3314 28.6983C19.464 29.199 19.0865 29.6895 18.5687 29.6895H10.7898C10.2718 29.6895 9.89439 29.199 10.027 28.6983C10.027 28.6983 10.027 28.6983 10.027 28.6983L11.1475 24.4685L11.1475 24.4685C11.1892 24.3109 11.3319 24.2012 11.4949 24.2012H17.8636C18.0266 24.2012 18.1692 24.3109 18.211 24.4685Z" stroke="white" />
    //     <path d="M30.972 28.6983L30.9721 28.6984C31.1047 29.199 30.7272 29.6895 30.2093 29.6895H22.4304C21.9125 29.6895 21.535 29.199 21.6676 28.6983C21.6676 28.6983 21.6676 28.6983 21.6676 28.6983L22.7881 24.4685L22.7881 24.4685C22.8299 24.3109 22.9725 24.2012 23.1355 24.2012H29.5042C29.6672 24.2012 29.8098 24.3109 29.8515 24.4685L29.8516 24.4685L30.972 28.6983Z" stroke="white" />
    //     <path d="M16.1289 20.9197L16.1289 20.9197L17.2452 16.7336C17.2452 16.7336 17.2452 16.7336 17.2452 16.7336C17.2872 16.5763 17.4297 16.4668 17.5925 16.4668H23.4047C23.5675 16.4668 23.71 16.5763 23.752 16.7336L24.8683 20.9197L25.3514 20.7909L24.8683 20.9197C25.0019 21.4206 24.6244 21.9121 24.1059 21.9121H16.8913C16.3729 21.9121 15.9953 21.4206 16.1289 20.9197Z" stroke="white" />
    //     <path d="M20.6445 12.7441C20.6445 12.824 20.5798 12.8887 20.5 12.8887C20.4202 12.8887 20.3555 12.824 20.3555 12.7441V11.4551C20.3555 11.3753 20.4202 11.3105 20.5 11.3105C20.5798 11.3105 20.6445 11.3753 20.6445 11.4551V12.7441Z" stroke="white" />
    //     <path d="M24.295 13.9055L24.295 13.9055C24.2259 13.8656 24.2021 13.7772 24.2421 13.708C24.2421 13.708 24.2421 13.708 24.2421 13.708L25.5726 11.4035L25.5727 11.4034C25.6126 11.3343 25.7009 11.3106 25.77 11.3505L25.77 11.3506C25.8392 11.3905 25.8628 11.4789 25.8229 11.548L26.2358 11.7864L25.8229 11.548L24.4924 13.8526L24.4923 13.8526C24.4525 13.9217 24.3641 13.9454 24.295 13.9055Z" stroke="white" />
    //     <path d="M27.2716 16.7366L27.2716 16.7366C27.2023 16.7766 27.1141 16.7528 27.0742 16.6837L27.0742 16.6837C27.0342 16.6146 27.0579 16.5262 27.127 16.4863L27.127 16.4863L28.2434 15.8418L28.2434 15.8417C28.3125 15.8018 28.4009 15.8255 28.4408 15.8946L28.4408 15.8947C28.4807 15.9638 28.457 16.0522 28.3879 16.0921L28.3879 16.0921L27.2716 16.7366Z" stroke="white" />
    //     <path d="M16.7037 13.9054L16.7037 13.9055C16.6346 13.9454 16.5462 13.9217 16.5063 13.8525C16.5063 13.8525 16.5063 13.8525 16.5063 13.8525L15.1757 11.548L15.1757 11.548C15.1358 11.4788 15.1595 11.3904 15.2286 11.3505L15.2287 11.3505C15.2977 11.3106 15.3861 11.3343 15.426 11.4034C15.426 11.4034 15.426 11.4034 15.426 11.4034L16.7566 13.708L16.7566 13.708C16.7965 13.7771 16.7728 13.8655 16.7037 13.9054Z" stroke="white" />
    //     <path d="M12.6114 16.0918L12.6114 16.0918C12.5423 16.0519 12.5186 15.9636 12.5585 15.8944C12.5985 15.8252 12.6869 15.8016 12.7559 15.8415L12.756 15.8415L13.8723 16.4861L13.8723 16.4861C13.9414 16.526 13.9651 16.6144 13.9252 16.6835L13.9252 16.6835C13.8853 16.7526 13.7969 16.7763 13.7278 16.7364L13.7278 16.7364L12.6114 16.0918Z" stroke="white" />
    //   </g>
    //   <defs>
    //     <clipPath id="clip0_267_54">
    //       <rect width="22" height="22" fill="white" transform="translate(9.5 9.5)" />
    //     </clipPath>
    //   </defs>
    // </svg>
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_267_56)">
        <path d="M9.21096 15.4685L9.21097 15.4685L10.3314 19.6983C10.464 20.199 10.0865 20.6895 9.56866 20.6895H1.78977C1.27185 20.6895 0.894391 20.199 1.027 19.6983C1.027 19.6983 1.027 19.6983 1.02701 19.6983L2.1475 15.4685L2.14751 15.4685C2.18924 15.3109 2.33186 15.2012 2.49489 15.2012H8.86359C9.02661 15.2012 9.16923 15.3109 9.21096 15.4685Z" stroke="#EAA22B" />
        <path d="M21.972 19.6983L21.9721 19.6984C22.1047 20.199 21.7272 20.6895 21.2093 20.6895H13.4304C12.9125 20.6895 12.535 20.199 12.6676 19.6983C12.6676 19.6983 12.6676 19.6983 12.6676 19.6983L13.7881 15.4685L13.7881 15.4685C13.8299 15.3109 13.9725 15.2012 14.1355 15.2012H20.5042C20.6672 15.2012 20.8098 15.3109 20.8515 15.4685L20.8516 15.4685L21.972 19.6983Z" stroke="#EAA22B" />
        <path d="M7.12891 11.9197L7.12891 11.9197L8.24524 7.7336C8.24524 7.7336 8.24524 7.73359 8.24524 7.73359C8.28721 7.57625 8.4297 7.4668 8.59249 7.4668H14.4047C14.5675 7.4668 14.71 7.57626 14.752 7.7336L15.8683 11.9197L16.3514 11.7909L15.8683 11.9197C16.0019 12.4206 15.6244 12.9121 15.1059 12.9121H7.89133C7.37289 12.9121 6.99533 12.4206 7.12891 11.9197Z" stroke="#EAA22B" />
        <path d="M11.6445 3.74414C11.6445 3.82395 11.5798 3.88867 11.5 3.88867C11.4202 3.88867 11.3555 3.82395 11.3555 3.74414V2.45508C11.3555 2.37527 11.4202 2.31055 11.5 2.31055C11.5798 2.31055 11.6445 2.37527 11.6445 2.45508V3.74414Z" stroke="#EAA22B" />
        <path d="M15.295 4.9055L15.295 4.90546C15.2259 4.86557 15.2021 4.77722 15.2421 4.70805C15.2421 4.70804 15.2421 4.70803 15.2421 4.70802L16.5726 2.40346L16.5727 2.40339C16.6126 2.33429 16.7009 2.31062 16.77 2.35053L16.77 2.35057C16.8392 2.39047 16.8628 2.47887 16.8229 2.54798L17.2559 2.79799L16.8229 2.54799L15.4924 4.85257L15.4923 4.85261C15.4525 4.92169 15.3641 4.94537 15.295 4.9055Z" stroke="#EAA22B" />
        <path d="M18.2716 7.7366L18.2716 7.7366C18.2023 7.77657 18.1141 7.75284 18.0742 7.68374L18.0742 7.68371C18.0342 7.61459 18.0579 7.52619 18.127 7.48629L18.127 7.48629L19.2434 6.84176L19.2434 6.84175C19.3125 6.80182 19.4009 6.8255 19.4408 6.89463L19.4408 6.89465C19.4807 6.96377 19.457 7.05217 19.3879 7.09207L19.3879 7.09207L18.2716 7.7366Z" stroke="#EAA22B" />
        <path d="M7.7037 4.90543L7.70367 4.90545C7.63455 4.94536 7.54624 4.92172 7.50629 4.85254C7.50629 4.85254 7.50628 4.85254 7.50628 4.85253L6.17572 2.54796L6.17572 2.54796C6.13581 2.47884 6.1595 2.39044 6.22861 2.35054L6.22865 2.35051C6.2977 2.31064 6.38609 2.33427 6.42602 2.40343C6.42602 2.40343 6.42602 2.40343 6.42602 2.40343L7.75659 4.70802L7.75659 4.70802C7.7965 4.77713 7.77281 4.86553 7.7037 4.90543Z" stroke="#EAA22B" />
        <path d="M3.61143 7.09184L3.6114 7.09182C3.54232 7.05194 3.51861 6.96358 3.55854 6.89442C3.59849 6.82522 3.68685 6.80162 3.75589 6.84149L3.75595 6.84153L4.87228 7.48606L4.87228 7.48606C4.94139 7.52596 4.96508 7.61436 4.92517 7.68347L4.92516 7.68349C4.88528 7.75258 4.79686 7.77627 4.72781 7.73639L4.72776 7.73637L3.61143 7.09184Z" stroke="#EAA22B" />
      </g>
      <defs>
        <clipPath id="clip0_267_56">
          <rect width="22" height="22" fill="#eaa22b" transform="translate(0.5 0.5)" />
        </clipPath>
      </defs>
    </svg>

  )
}

export default SmartMetalsIcon
