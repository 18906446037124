import React from 'react'

function EyeOffIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1082_64332)">
        <path d="M12.483 10.1685L13.6189 9.03254C13.7016 9.34176 13.75 9.66504 13.75 10C13.75 12.0679 12.0678 13.75 10 13.75C9.665 13.75 9.34172 13.7016 9.0325 13.6189L10.1684 12.483C11.4105 12.3983 12.3983 11.4105 12.483 10.1685ZM19.9212 9.69644C19.8444 9.55816 18.7541 7.6477 16.6228 6.02863L15.7133 6.93816C17.2673 8.08082 18.252 9.41348 18.6397 10.001C17.8989 11.1289 14.993 15 10 15C9.25125 15 8.555 14.9043 7.89984 14.7514L6.87281 15.7785C7.81703 16.068 8.85484 16.25 10 16.25C16.5427 16.25 19.7864 10.5463 19.9212 10.3036C20.0263 10.1147 20.0263 9.88535 19.9212 9.69644ZM17.3169 3.56691L3.56687 17.3169C3.44484 17.4389 3.28484 17.5 3.125 17.5C2.96516 17.5 2.80516 17.4389 2.68313 17.3169C2.43891 17.0727 2.43891 16.6772 2.68313 16.4332L4.4325 14.6838C1.60437 12.9764 0.167344 10.4632 0.07875 10.3036C-0.02625 10.1147 -0.02625 9.88519 0.07875 9.69629C0.213594 9.45379 3.45734 3.75004 10 3.75004C11.6917 3.75004 13.157 4.13598 14.4097 4.7066L16.4331 2.68316C16.6773 2.43895 17.0728 2.43895 17.3169 2.68316C17.5609 2.92738 17.5611 3.32285 17.3169 3.56691ZM5.36453 13.7518L6.94687 12.1694C6.50984 11.5563 6.25 10.8086 6.25 10C6.25 7.93223 7.93219 6.25004 10 6.25004C10.8086 6.25004 11.5563 6.50988 12.1694 6.94676L13.4489 5.66723C12.438 5.26238 11.2892 5.00004 10 5.00004C5.00703 5.00004 2.10109 8.87113 1.36047 9.9991C1.83078 10.7119 3.18266 12.5163 5.36453 13.7518ZM7.85422 11.2619L11.2619 7.85426C10.8902 7.63473 10.4622 7.50004 10 7.50004C8.62156 7.50004 7.5 8.6216 7.5 10C7.5 10.4622 7.63469 10.8902 7.85422 11.2619Z" fill="#1D2129" />
      </g>
      <defs>
        <clipPath id="clip0_1082_64332">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default EyeOffIcon