import React from "react"
import { SvgIcon, type SvgIconProps } from "@mui/material"

function AccountsIcon(props: SvgIconProps) {
  return (
    // <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <rect width="41" height="41" rx="10" fill="#EAA22B" />
    //   <path d="M20.5 21.5831C23.7151 21.5831 26.294 19.0043 26.294 15.7911H25.294C25.294 18.4518 23.1631 20.5831 20.5 20.5831V21.5831ZM14.706 15.7911C14.706 19.0044 17.2859 21.5831 20.5 21.5831V20.5831C17.8379 20.5831 15.706 18.4518 15.706 15.7911H14.706ZM20.5 10C17.2861 10 14.706 12.5776 14.706 15.7911H15.706C15.706 13.1305 17.8377 11 20.5 11V10ZM26.294 15.7911C26.294 12.5776 23.715 10 20.5 10V11C23.1632 11 25.294 13.1304 25.294 15.7911H26.294ZM12 27.075C12 27.8798 12.2756 28.5544 12.7714 29.0955C13.2569 29.6252 13.9324 30.0047 14.7065 30.2788C16.2481 30.8247 18.319 31 20.5 31V30C18.3433 30 16.4143 29.8228 15.0403 29.3362C14.3566 29.0941 13.8454 28.7873 13.5086 28.4198C13.1822 28.0636 13 27.6302 13 27.075H12ZM20.5 23.1739C18.3314 23.1739 16.261 23.3428 14.7172 23.882C13.942 24.1528 13.264 24.5287 12.7762 25.0561C12.2777 25.595 12 26.269 12 27.075H13C13 26.5205 13.1829 26.089 13.5103 25.7351C13.8483 25.3697 14.3614 25.0655 15.0469 24.8261C16.4244 24.345 18.3539 24.1739 20.5 24.1739V23.1739ZM29 27.099C29 26.2942 28.7245 25.6195 28.2288 25.0785C27.7434 24.5487 27.068 24.1693 26.2939 23.8951C24.7524 23.3492 22.6815 23.1739 20.5 23.1739V24.1739C22.6572 24.1739 24.5862 24.3512 25.9601 24.8378C26.6437 25.0799 27.1548 25.3866 27.4915 25.7541C27.8178 26.1103 28 26.5437 28 27.099H29ZM20.5 31C22.6686 31 24.739 30.8311 26.2828 30.2919C27.058 30.0212 27.736 29.6452 28.2238 29.1178C28.7223 28.5789 29 27.905 29 27.099H28C28 27.6535 27.8171 28.0849 27.4897 28.4388C27.1517 28.8042 26.6386 29.1084 25.9531 29.3478C24.5756 29.8289 22.6461 30 20.5 30V31Z" fill="white" />
    // </svg>
    <svg width="17" height="21" viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.5 11.5831C11.7151 11.5831 14.294 9.00433 14.294 5.79105H13.294C13.294 8.45183 11.1631 10.5831 8.5 10.5831V11.5831ZM2.70601 5.79105C2.70601 9.0044 5.28593 11.5831 8.5 11.5831V10.5831C5.83787 10.5831 3.70601 8.45177 3.70601 5.79105H2.70601ZM8.5 0C5.28605 0 2.70601 2.57759 2.70601 5.79105H3.70601C3.70601 3.13046 5.83775 1 8.5 1V0ZM14.294 5.79105C14.294 2.57765 11.715 0 8.5 0V1C11.1632 1 13.294 3.1304 13.294 5.79105H14.294ZM0 17.075C0 17.8798 0.27559 18.5544 0.771407 19.0955C1.2569 19.6252 1.93235 20.0047 2.70648 20.2788C4.24807 20.8247 6.31903 21 8.5 21V20C6.34334 20 4.4143 19.8228 3.0403 19.3362C2.35663 19.0941 1.84539 18.7873 1.50864 18.4198C1.18221 18.0636 1 17.6302 1 17.075H0ZM8.5 13.1739C6.33143 13.1739 4.26102 13.3428 2.71716 13.882C1.94197 14.1528 1.26404 14.5287 0.776209 15.0561C0.277731 15.595 0 16.269 0 17.075H1C1 16.5205 1.18294 16.089 1.5103 15.7351C1.84831 15.3697 2.36139 15.0655 3.04688 14.8261C4.42436 14.345 6.35395 14.1739 8.5 14.1739V13.1739ZM17 17.099C17 16.2942 16.7245 15.6195 16.2288 15.0785C15.7434 14.5487 15.068 14.1693 14.2939 13.8951C12.7524 13.3492 10.6815 13.1739 8.5 13.1739V14.1739C10.6572 14.1739 12.5862 14.3512 13.9601 14.8378C14.6437 15.0799 15.1548 15.3866 15.4915 15.7541C15.8178 16.1103 16 16.5437 16 17.099H17ZM8.5 21C10.6686 21 12.739 20.8311 14.2828 20.2919C15.058 20.0212 15.736 19.6452 16.2238 19.1178C16.7223 18.5789 17 17.905 17 17.099H16C16 17.6535 15.8171 18.0849 15.4897 18.4388C15.1517 18.8042 14.6386 19.1084 13.9531 19.3478C12.5756 19.8289 10.6461 20 8.5 20V21Z" fill="#EAA22B" />
    </svg>

  )
}

export default AccountsIcon