import React from "react"
import { SvgIcon, type SvgIconProps } from "@mui/material"

function MyVaultIcon(props: SvgIconProps) {
    return (
        // <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        //     <rect width="41" height="41" rx="10" fill="#3491FA" />
        //     <g clipPath="url(#clip0_267_109)">
        //         <path d="M22.371 23.3161V21.4011C22.6547 21.1849 22.8909 20.9207 23.0814 20.6262H30.3959C30.7247 20.6262 30.9959 20.8973 30.9959 21.2261V25.1091C30.9959 28.3634 28.3603 31 25.0939 31H15.887C12.6322 31 9.99609 28.3639 9.99609 25.1091V21.2261C9.99609 20.8973 10.2672 20.6262 10.5961 20.6262H17.9106C18.101 20.9207 18.3372 21.1849 18.621 21.4011V23.3161C18.621 24.3513 19.4608 25.1911 20.496 25.1911C21.5311 25.1911 22.371 24.3513 22.371 23.3161Z" stroke="white" />
        //         <path d="M15.887 10H25.1049C28.3597 10 30.9959 12.6361 30.9959 15.8909V17.3759C30.9959 17.7048 30.7247 17.9759 30.3959 17.9759H23.4719C23.1613 16.9825 22.3378 16.1899 21.3276 15.9245C19.612 15.469 18.024 16.4717 17.5423 17.9759H10.5961C10.2672 17.9759 9.99609 17.7048 9.99609 17.3759V15.8909C9.99609 12.6361 12.6322 10 15.887 10Z" stroke="white" />
        //     </g>
        //     <defs>
        //         <clipPath id="clip0_267_109">
        //             <rect width="22" height="22" fill="white" transform="translate(9.5 9.5)" />
        //         </clipPath>
        //     </defs>
        // </svg>
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_267_111)">
                <path d="M13.371 14.3161V12.4011C13.6547 12.1849 13.8909 11.9207 14.0814 11.6262H21.3959C21.7247 11.6262 21.9959 11.8973 21.9959 12.2261V16.1091C21.9959 19.3634 19.3603 22 16.0939 22H6.88702C3.6322 22 0.996094 19.3639 0.996094 16.1091V12.2261C0.996094 11.8973 1.26723 11.6262 1.59608 11.6262H8.91058C9.101 11.9207 9.33722 12.1849 9.62099 12.4011V14.3161C9.62099 15.3513 10.4608 16.1911 11.496 16.1911C12.5311 16.1911 13.371 15.3513 13.371 14.3161Z" stroke="#EAA22B" />
                <path d="M6.88702 1H16.1049C19.3597 1 21.9959 3.63611 21.9959 6.89093V8.37591C21.9959 8.70477 21.7247 8.9759 21.3959 8.9759H14.4719C14.1613 7.98255 13.3378 7.18994 12.3276 6.9245C10.612 6.469 9.02399 7.4717 8.54229 8.9759H1.59608C1.26723 8.9759 0.996094 8.70477 0.996094 8.37591V6.89093C0.996094 3.63611 3.6322 1 6.88702 1Z" stroke="#EAA22B" />
            </g>
            <defs>
                <clipPath id="clip0_267_111">
                    <rect width="22" height="22" fill="#EAA22B" transform="translate(0.5 0.5)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default MyVaultIcon