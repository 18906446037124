import React from "react"
import { SvgIcon, type SvgIconProps } from "@mui/material"

function PrivateHoldingIcon(props: SvgIconProps) {
  return (
    // <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <rect width="41" height="41" rx="10" fill="#EAA22B" />
    //   <path d="M14.9882 21.7888L14.9881 21.7887L14.9882 21.7888ZM15.449 22.0999V21.3958L17.8188 22.764V26.6505L17.0411 27.0995L11.6477 23.9856V17.7577L14.2086 16.2792V17.5403L12.9899 18.2439L12.7399 18.3883V18.677V21.2755C12.7399 21.5059 12.8609 21.7152 13.0601 21.8303L13.0602 21.8303L14.4879 22.6546C14.488 22.6546 14.488 22.6547 14.488 22.6547C14.9156 22.9016 15.449 22.5932 15.449 22.0999ZM22.4443 10.958L17.3008 13.9276L17.0508 14.072V14.3607V16.9592C17.0508 17.1896 17.1718 17.3989 17.371 17.514L17.3711 17.514L18.799 18.3384L18.7991 18.3385C19.2263 18.585 19.7599 18.2773 19.7599 17.7836V15.6361L25.1533 12.5222L26.7456 13.4414V19.6692L21.3521 22.7832L15.9586 19.6692V13.4414L21.3521 10.3275L22.4443 10.958ZM20.9814 26.4036L22.4091 27.2279C22.4091 27.2279 22.4092 27.2279 22.4092 27.228C22.8367 27.4748 23.3702 27.1665 23.3702 26.6732V24.5257L28.7636 21.4117L30.3558 22.331V28.5588L24.9623 31.6727L19.5688 28.5588V23.7744L20.4927 24.3078C20.5476 24.3395 20.6038 24.368 20.661 24.3933V25.8488C20.661 26.0792 20.782 26.2884 20.9813 26.4035L20.9814 26.4036ZM22.9094 26.362L22.9092 26.3619L22.9094 26.362Z" stroke="white" />
    // </svg>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.9814 17.4036L13.4091 18.2279C13.8366 18.4749 14.3702 18.1665 14.3702 17.6732V15.5257L19.7636 12.4117L21.3558 13.331V19.5588L15.9623 22.6727L10.5688 19.5588V14.7744L11.4927 15.3078C11.5476 15.3395 11.6038 15.368 11.661 15.3933V16.8488C11.661 17.0792 11.782 17.2884 11.9813 17.4035L11.9814 17.4036ZM13.9092 17.3619L13.9087 17.3617C13.9089 17.3618 13.9091 17.3619 13.9094 17.362L13.9092 17.3619ZM5.98819 12.7888L5.98805 12.7887L5.98819 12.7888ZM6.44899 13.0999V12.3958L8.81878 13.764V17.6505L8.04111 18.0995L2.64771 14.9856V8.7577L5.20856 7.27921V8.54032L3.98987 9.24395L3.73988 9.38828V9.67696V12.2755C3.73988 12.5059 3.86086 12.7152 4.06012 12.8303L4.06019 12.8303L5.48791 13.6546C5.48796 13.6546 5.488 13.6547 5.48804 13.6547C5.91557 13.9016 6.44899 13.5932 6.44899 13.0999ZM12.3521 1.32746L13.4443 1.95804L8.30078 4.92765L8.05078 5.07198V5.36066V7.95922C8.05078 8.18962 8.17176 8.39889 8.37102 8.51397L8.37109 8.51401L9.79895 9.3384L9.79907 9.33848C10.2263 9.58497 10.7599 9.27729 10.7599 8.78361V6.6361L16.1533 3.52216L17.7456 4.4414V10.6692L12.3521 13.7832L6.95856 10.6692V4.4414L12.3521 1.32746Z" stroke="#EAA22B" />
    </svg>
  )
}

export default PrivateHoldingIcon


