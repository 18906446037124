import React from "react"
import { SvgIcon, type SvgIconProps } from "@mui/material"

function CameraIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M22.8913 6.21749H20.1783L18.6804 3.95662C18.1152 3.10879 17.1261 2.6001 16.1087 2.6001H9.8913C8.87391 2.6001 7.88478 3.10879 7.31957 3.95662L5.82174 6.21749H3.1087C1.38478 6.21749 0 7.60227 0 9.32618V20.2914C0 22.0153 1.38478 23.4001 3.1087 23.4001H22.8913C24.6152 23.4001 26 22.0153 26 20.2914V9.32618C26 7.60227 24.6152 6.21749 22.8913 6.21749ZM13 20.8566C9.12826 20.8566 5.9913 17.7197 5.9913 13.8479C5.9913 9.97618 9.12826 6.86749 13 6.86749C16.8717 6.86749 20.0087 10.0044 20.0087 13.8762C20.0087 17.7197 16.8717 20.8566 13 20.8566ZM22.4957 10.5979C22.4674 10.5979 22.4391 10.5979 22.3826 10.5979H21.2522C20.7435 10.5697 20.3478 10.1457 20.3761 9.63705C20.4043 9.15662 20.7717 8.78923 21.2522 8.76097H22.3826C22.8913 8.73271 23.3152 9.12836 23.3435 9.63705C23.3717 10.1457 23.0043 10.5697 22.4957 10.5979Z" fill="currentColor" />
      <path d="M12.9996 9.97607C10.8518 9.97607 9.09961 11.7282 9.09961 13.8761C9.09961 16.0239 10.8518 17.7478 12.9996 17.7478C15.1474 17.7478 16.8996 15.9956 16.8996 13.8478C16.8996 11.7 15.1474 9.97607 12.9996 9.97607Z" fill="currentColor" />
    </SvgIcon>
  )
}

export default CameraIcon
