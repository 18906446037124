
import React from "react"
import { SvgIcon, type SvgIconProps } from "@mui/material"

function MySilverIcon(props: SvgIconProps) {
    return (
        // <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        //     <rect width="41" height="41" rx="10" fill="#FF1F1F" />
        //     <g clipPath="url(#clip0_267_123)">
        //         <path d="M23.2915 11C18.844 11 15.2383 15.5548 15.2383 21.1727C15.2383 26.7913 18.844 31.3453 23.2915 31.3453C27.7395 31.3453 31.3449 26.7913 31.3449 21.1727C31.3449 15.5548 27.7395 11 23.2915 11ZM23.2915 28.8021C20.3048 28.8021 17.7814 25.3086 17.7814 21.1727C17.7814 17.0367 20.3048 13.5432 23.2915 13.5432C26.2784 13.5432 28.8017 17.0367 28.8017 21.1727C28.8017 25.3086 26.2784 28.8021 23.2915 28.8021Z" stroke="white" />
        //         <path d="M23.2931 27.5305C21.0348 27.5305 19.0547 24.5593 19.0547 21.1726C19.0547 17.7859 21.0348 14.8147 23.2931 14.8147C25.5507 14.8147 27.5318 17.7859 27.5318 21.1726C27.5318 24.5593 25.5507 27.5305 23.2931 27.5305Z" stroke="white" />
        //         <path d="M19.04 11.0007C17.1967 11.0058 15.5039 11.7998 14.1484 13.1192H16.6774C17.3653 12.2682 18.1638 11.5557 19.04 11.0007Z" stroke="white" />
        //         <path d="M13.9671 21.1728C13.9671 20.8865 13.9852 20.606 14.0025 20.3252H11.0341C11.0155 20.606 11 20.8865 11 21.1728C11 21.4592 11.0155 21.7397 11.0341 22.0205H14.0025C13.9852 21.7397 13.9671 21.4592 13.9671 21.1728Z" stroke="white" />
        //         <path d="M14.955 16.0864C15.1978 15.4895 15.4814 14.9234 15.8021 14.3909H13.0669C12.6974 14.9117 12.3696 15.4778 12.0898 16.0864H14.955Z" stroke="white" />
        //         <path d="M15.8016 27.9546C15.4812 27.422 15.1971 26.8559 14.9547 26.259H12.0898C12.3691 26.8676 12.6974 27.4337 13.0664 27.9546H15.8016Z" stroke="white" />
        //         <path d="M16.6774 29.2263H14.1484C15.5039 30.5457 17.1964 31.3399 19.0395 31.3448C18.1633 30.7899 17.3653 30.0773 16.6774 29.2263Z" stroke="white" />
        //         <path d="M14.5133 24.9875C14.3548 24.4395 14.2256 23.8749 14.1364 23.292H11.1797C11.2784 23.8782 11.4185 24.4435 11.593 24.9875H14.5133Z" stroke="white" />
        //         <path d="M14.1361 19.0534C14.2256 18.4705 14.3546 17.906 14.5135 17.3579H11.5932C11.4185 17.9019 11.2784 18.4672 11.1797 19.0534H14.1361Z" stroke="white" />
        //     </g>
        //     <defs>
        //         <clipPath id="clip0_267_123">
        //             <rect width="22" height="22" fill="white" transform="translate(10 10)" />
        //         </clipPath>
        //     </defs>
        // </svg>
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_267_125)">
                <path d="M13.2915 1C8.84398 1 5.23828 5.55481 5.23828 11.1727C5.23828 16.7913 8.84398 21.3453 13.2915 21.3453C17.7395 21.3453 21.3449 16.7913 21.3449 11.1727C21.3449 5.55481 17.7395 1 13.2915 1ZM13.2915 18.8021C10.3048 18.8021 7.78145 15.3086 7.78145 11.1727C7.78145 7.03671 10.3048 3.54316 13.2915 3.54316C16.2784 3.54316 18.8017 7.03671 18.8017 11.1727C18.8017 15.3086 16.2784 18.8021 13.2915 18.8021Z" stroke="#EAA22B" />
                <path d="M13.2931 17.5305C11.0348 17.5305 9.05469 14.5593 9.05469 11.1726C9.05469 7.78588 11.0348 4.8147 13.2931 4.8147C15.5507 4.8147 17.5318 7.78588 17.5318 11.1726C17.5318 14.5593 15.5507 17.5305 13.2931 17.5305Z" stroke="#EAA22B" />
                <path d="M9.03996 1.00073C7.19667 1.00582 5.50394 1.79979 4.14844 3.11919H6.67736C7.36529 2.26825 8.16384 1.55565 9.03996 1.00073Z" stroke="#EAA22B" />
                <path d="M3.96711 11.1728C3.96711 10.8865 3.98517 10.606 4.00246 10.3252H1.03408C1.01551 10.606 1 10.8865 1 11.1728C1 11.4592 1.01551 11.7397 1.03408 12.0205H4.00246C3.98517 11.7397 3.96711 11.4592 3.96711 11.1728Z" stroke="#EAA22B" />
                <path d="M4.95497 6.0864C5.19784 5.48952 5.48141 4.92341 5.8021 4.39087H3.06693C2.69741 4.91171 2.36959 5.47782 2.08984 6.0864H4.95497Z" stroke="#EAA22B" />
                <path d="M5.80159 17.9546C5.48115 17.422 5.19708 16.8559 4.95472 16.259H2.08984C2.36908 16.8676 2.69741 17.4337 3.06642 17.9546H5.80159Z" stroke="#EAA22B" />
                <path d="M6.67736 19.2263H4.14844C5.50394 20.5457 7.19642 21.3399 9.03945 21.3448C8.16333 20.7899 7.36529 20.0773 6.67736 19.2263Z" stroke="#EAA22B" />
                <path d="M4.51327 14.9875C4.35483 14.4395 4.22564 13.8749 4.13637 13.292H1.17969C1.27836 13.8782 1.41849 14.4435 1.59295 14.9875H4.51327Z" stroke="#EAA22B" />
                <path d="M4.13612 9.05344C4.22564 8.47054 4.35457 7.90596 4.51352 7.35791H1.59321C1.41849 7.90189 1.27836 8.46724 1.17969 9.05344H4.13612Z" stroke="#EAA22B" />
            </g>
            <defs>
                <clipPath id="clip0_267_125">
                    <rect width="22" height="22" fill="#EAA22B" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default MySilverIcon