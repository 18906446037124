import React from "react"
import { SvgIcon, type SvgIconProps } from "@mui/material"

function BuyBackOrderIcon(props: SvgIconProps) {
  return (
    // <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <rect width="41" height="41" rx="10" fill="#008001" />
    //   <path d="M20.9325 11.9853L21.4319 11.9846V11.4853V10.3556C21.4326 10.306 21.4459 10.2575 21.4706 10.2145C21.4956 10.171 21.5313 10.1347 21.5744 10.1091C21.6175 10.0834 21.6664 10.0693 21.7165 10.0681C21.7658 10.0669 21.8146 10.0781 21.8583 10.1008L28.5071 13.7463C28.5861 13.792 28.6511 13.8586 28.6949 13.9387C28.7393 14.0199 28.7603 14.1118 28.7555 14.2041C28.7508 14.2965 28.7204 14.3858 28.668 14.4619C28.6159 14.5375 28.5439 14.5973 28.4601 14.6347C28.4595 14.635 28.4588 14.6352 28.4582 14.6355L21.5151 17.616C21.5146 17.6162 21.5141 17.6164 21.5136 17.6166C21.4699 17.6347 21.4224 17.6419 21.3753 17.6375C21.3276 17.6331 21.2817 17.617 21.2418 17.5907C21.2018 17.5643 21.1689 17.5286 21.146 17.4865C21.1234 17.445 21.1112 17.3986 21.1105 17.3513L21.1105 15.8697L21.1106 15.3919L20.6333 15.3702C17.9382 15.2477 14.674 16.1402 12.2633 18.2811C12.625 15.4246 15.7945 11.9921 20.9325 11.9853Z" stroke="white" />
    //   <path d="M13.0899 20.0146L13.0899 20.0146L13.0931 20.0111C13.4119 19.6684 13.7574 19.3523 14.1261 19.0658C14.0624 19.3541 14.0052 19.6596 13.9563 19.9703C13.8302 20.7702 13.752 21.6476 13.7657 22.4223C13.266 21.8706 12.8669 21.2356 12.5865 20.5461C12.6021 20.5295 12.618 20.5128 12.634 20.4959C12.7788 20.3429 12.9357 20.1773 13.0899 20.0146Z" stroke="white" />
    //   <path d="M20.0697 29.0147L19.5704 29.0154V29.5147V30.6444C19.5697 30.6939 19.5563 30.7425 19.5316 30.7855C19.5067 30.8289 19.4709 30.8652 19.4279 30.8909C19.3848 30.9165 19.3358 30.9307 19.2857 30.9319C19.2365 30.9331 19.1877 30.9218 19.144 30.8991L12.4952 27.2537C12.4162 27.208 12.3512 27.1414 12.3074 27.0613C12.263 26.9801 12.242 26.8882 12.2468 26.7958C12.2515 26.7035 12.2818 26.6142 12.3343 26.5381C12.3864 26.4625 12.4584 26.4027 12.5422 26.3653L19.4886 23.3833C19.5324 23.3653 19.5799 23.3581 19.627 23.3625C19.6747 23.3669 19.7205 23.383 19.7605 23.4093C19.8005 23.4356 19.8334 23.4714 19.8563 23.5134C19.8789 23.555 19.8911 23.6014 19.8918 23.6487L19.8917 25.1303L19.8917 25.6081L20.369 25.6298C23.0641 25.7523 26.3282 24.8598 28.7389 22.7189C28.3772 25.5754 25.2078 29.0079 20.0697 29.0147Z" stroke="white" />
    //   <path d="M27.9097 20.9854L27.9097 20.9854L27.9064 20.9889C27.5877 21.3316 27.2422 21.6477 26.8735 21.9342C26.9371 21.6459 26.9943 21.3404 27.0433 21.0297C27.1693 20.2298 27.2476 19.3525 27.2338 18.5777C27.7335 19.1294 28.1327 19.7644 28.4131 20.4539C28.3932 20.4749 28.3731 20.4961 28.3528 20.5176C28.2115 20.6668 28.0594 20.8275 27.9097 20.9854Z" stroke="white" />
    // </svg>
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.9325 3.98528L13.4319 3.98462V3.48528V2.35558C13.4326 2.30604 13.4459 2.25749 13.4706 2.21451C13.4956 2.17104 13.5313 2.13473 13.5744 2.10908C13.6175 2.08343 13.6664 2.06931 13.7165 2.06808C13.7658 2.06687 13.8145 2.07815 13.8582 2.10084L20.5071 5.74625C20.5861 5.79202 20.6511 5.85859 20.6949 5.93873C20.7393 6.01988 20.7603 6.11177 20.7555 6.20415C20.7508 6.29653 20.7204 6.38577 20.668 6.46193C20.6159 6.53753 20.5439 6.59729 20.4601 6.63468C20.4595 6.63496 20.4588 6.63523 20.4582 6.63551L13.5151 9.61603C13.5146 9.61623 13.5141 9.61644 13.5136 9.61663C13.4699 9.63471 13.4224 9.64188 13.3753 9.6375C13.3276 9.63308 13.2817 9.61699 13.2418 9.59066C13.2018 9.56433 13.1689 9.52857 13.146 9.48654L12.7069 9.72566L13.146 9.48653C13.1234 9.44499 13.1112 9.39858 13.1105 9.3513L13.1105 7.86972L13.1106 7.39192L12.6333 7.37022C9.93816 7.24769 6.67403 8.14015 4.26333 10.2811C4.62505 7.42463 7.79447 3.99207 12.9325 3.98528Z" stroke="#EAA22B" />
      <path d="M5.08987 12.0146L5.08989 12.0146L5.09311 12.0111C5.41188 11.6684 5.75737 11.3523 6.12605 11.0658C6.06242 11.3541 6.00524 11.6596 5.95627 11.9703C5.83023 12.7702 5.75197 13.6476 5.76573 14.4223C5.26603 13.8706 4.86688 13.2356 4.58647 12.5461C4.60216 12.5295 4.61801 12.5127 4.63401 12.4958C4.77887 12.3429 4.93568 12.1772 5.08987 12.0146ZM6.04472 15.0738L5.89875 14.7385L6.04472 15.0738Z" stroke="#EAA22B" />
      <path d="M12.0697 21.0147L11.5704 21.0154V21.5147V22.6444C11.5697 22.6939 11.5563 22.7425 11.5316 22.7855C11.5067 22.8289 11.4709 22.8652 11.4279 22.8909C11.3848 22.9165 11.3358 22.9307 11.2857 22.9319C11.2365 22.9331 11.1877 22.9218 11.144 22.8991L4.49516 19.2537C4.41617 19.208 4.35121 19.1414 4.30737 19.0613C4.26298 18.9801 4.242 18.8882 4.24676 18.7958C4.25151 18.7035 4.28183 18.6142 4.33432 18.5381C4.38643 18.4625 4.45836 18.4027 4.5422 18.3653L11.4886 15.3833C11.5324 15.3653 11.5799 15.3581 11.627 15.3625C11.6747 15.3669 11.7205 15.383 11.7605 15.4093C11.8005 15.4356 11.8334 15.4714 11.8563 15.5134C11.8789 15.555 11.8911 15.6014 11.8918 15.6487L11.8917 17.1303L11.8917 17.6081L12.369 17.6298C15.0641 17.7523 18.3282 16.8598 20.7389 14.7189C20.3772 17.5754 17.2078 21.0079 12.0697 21.0147Z" stroke="#EAA22B" />
      <path d="M19.9097 12.9854L19.9097 12.9854L19.9064 12.9889C19.5877 13.3316 19.2422 13.6477 18.8735 13.9342C18.9371 13.6459 18.9943 13.3404 19.0433 13.0297C19.1693 12.2298 19.2476 11.3525 19.2338 10.5777C19.7335 11.1294 20.1327 11.7644 20.4131 12.4539C20.3932 12.4749 20.3731 12.4962 20.3528 12.5176C20.2115 12.6668 20.0594 12.8275 19.9097 12.9854Z" stroke="#EAA22B" />
    </svg>

  )
}

export default BuyBackOrderIcon
