import React from 'react'

function CardIcon() {
  return (
    <svg width="47" height="48" viewBox="0 0 47 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.5" width="47" height="47" rx="23.5" fill="#D13200" fillOpacity="0.1" />
      <path d="M32.1152 15.5001H18.3596C17.274 15.5001 16.3782 16.4208 16.3555 17.5557H28.6384C30.5817 17.5557 32.1607 19.2144 32.1607 21.2461V28.8572C33.2463 28.8334 34.1269 27.8969 34.1269 26.754V17.6033C34.1269 16.4446 33.2235 15.5001 32.1152 15.5001Z" fill="#FF1F1F" />
      <path d="M28.6384 19.1429H14.8828C13.7745 19.1429 12.8711 20.0874 12.8711 21.2461V22.7381H30.6425V21.2461C30.6425 20.0874 29.7392 19.1429 28.6384 19.1429Z" fill="#FF1F1F" />
      <path d="M12.8711 30.397C12.8711 31.5557 13.7745 32.5001 14.8828 32.5001H28.6384C29.7392 32.5001 30.6425 31.5557 30.6425 30.397V24.3256H12.8711V30.397ZM24.949 27.7382H27.2264C27.644 27.7382 27.9856 28.0874 27.9856 28.5319C27.9856 28.9684 27.644 29.3255 27.2264 29.3255H24.949C24.5315 29.3255 24.1899 28.9684 24.1899 28.5319C24.1899 28.0874 24.5315 27.7382 24.949 27.7382Z" fill="#FF1F1F" />
    </svg>
  )
}

export default CardIcon