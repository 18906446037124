import React from 'react'

function PencilIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="16" fill="#E5E6EB" />
      <path d="M10.668 22.6667H21.3346" stroke="#1D2129" strokeWidth="0.947368" strokeLinecap="round" />
      <path d="M17.26 10.4419L17.7543 9.94753C18.5734 9.12849 19.9013 9.12849 20.7204 9.94753C21.5394 10.7666 21.5394 12.0945 20.7204 12.9135L20.226 13.4079L15.6814 17.9525C15.3735 18.2604 15.2196 18.4143 15.0499 18.5466C14.8497 18.7028 14.6331 18.8366 14.404 18.9459C14.2097 19.0385 14.0032 19.1073 13.5902 19.245L11.8402 19.8283L11.4124 19.9709C11.2092 20.0386 10.9851 19.9857 10.8336 19.8342C10.6822 19.6828 10.6293 19.4587 10.697 19.2555L10.8396 18.8277L11.4229 17.0777C11.5606 16.6647 11.6294 16.4582 11.722 16.2639C11.8312 16.0348 11.9651 15.8182 12.1212 15.618C12.2536 15.4483 12.4075 15.2944 12.7153 14.9865L17.26 10.4419Z" fill="#1D2129" />
      <path d="M17.26 10.4419L17.7543 9.94753C18.5734 9.12849 19.9013 9.12849 20.7204 9.94753C21.5394 10.7666 21.5394 12.0945 20.7204 12.9135L20.226 13.4079M17.26 10.4419C17.26 10.4419 17.3218 11.4923 18.2487 12.4192C19.1756 13.3461 20.226 13.4079 20.226 13.4079M17.26 10.4419L12.7153 14.9865C12.4075 15.2944 12.2536 15.4483 12.1212 15.618C11.9651 15.8182 11.8312 16.0348 11.722 16.2639C11.6294 16.4582 11.5606 16.6647 11.4229 17.0777L10.8396 18.8277M20.226 13.4079L15.6814 17.9525C15.3735 18.2604 15.2196 18.4143 15.0499 18.5466C14.8497 18.7028 14.6331 18.8366 14.404 18.9459C14.2097 19.0385 14.0032 19.1073 13.5902 19.245L11.8402 19.8283M11.8402 19.8283L11.4124 19.9709C11.2092 20.0386 10.9851 19.9857 10.8336 19.8342C10.6822 19.6828 10.6293 19.4587 10.697 19.2555L10.8396 18.8277M11.8402 19.8283L10.8396 18.8277" stroke="#1D2129" strokeWidth="0.947368" />
    </svg>

  )
}

export default PencilIcon