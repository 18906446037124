
import React from "react"
import { SvgIcon, type SvgIconProps } from "@mui/material"

function PhoneCall(props: SvgIconProps) {
    return (
        <SvgIcon
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" stroke="white" stroke-opacity="0.8" />
                <path d="M24.3298 21.6498V23.6567C24.3305 23.843 24.2923 24.0274 24.2175 24.1981C24.1427 24.3688 24.033 24.5221 23.8955 24.648C23.758 24.774 23.5956 24.8699 23.4187 24.9295C23.2419 24.9892 23.0546 25.0114 22.8686 24.9946C20.8062 24.771 18.8251 24.0675 17.0845 22.9409C15.4651 21.9139 14.0921 20.5435 13.0631 18.9272C11.9304 17.182 11.2254 15.1951 11.0054 13.1273C10.9887 12.9423 11.0107 12.7559 11.0701 12.5799C11.1295 12.4039 11.225 12.2421 11.3505 12.1049C11.4759 11.9677 11.6286 11.8581 11.7989 11.7831C11.9691 11.708 12.1531 11.6692 12.3392 11.669H14.3499C14.6752 11.6658 14.9905 11.7808 15.2372 11.9925C15.4838 12.2042 15.6449 12.4981 15.6904 12.8196C15.7753 13.4619 15.9327 14.0925 16.1596 14.6994C16.2497 14.9388 16.2693 15.199 16.2158 15.4492C16.1623 15.6994 16.0382 15.929 15.858 16.1109L15.0068 16.9605C15.9609 18.6352 17.3502 20.0219 19.0282 20.9742L19.8794 20.1246C20.0616 19.9448 20.2917 19.8208 20.5423 19.7675C20.793 19.7141 21.0537 19.7336 21.2936 19.8236C21.9017 20.0501 22.5335 20.2072 23.177 20.2919C23.5025 20.3377 23.7999 20.5014 24.0124 20.7518C24.225 21.0021 24.3379 21.3218 24.3298 21.6498Z" fill="white" />
                <path d="M19.6719 13.6758C20.3265 13.8033 20.9282 14.1229 21.3998 14.5936C21.8714 15.0643 22.1916 15.6648 22.3193 16.3182M19.6719 11C21.032 11.1508 22.3003 11.7587 23.2685 12.7239C24.2368 13.6891 24.8475 14.9542 25.0003 16.3115" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </SvgIcon>
    )
}

export default PhoneCall
