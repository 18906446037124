import React from "react"
import { SvgIcon, type SvgIconProps } from "@mui/material"

function SignInIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M3 5.93479C3 4.31394 4.06609 3 5.38115 3H10.8549C12.1699 3 13.236 4.31394 13.236 5.93479V7.45109C13.236 7.99137 12.8807 8.42935 12.4423 8.42935C12.0039 8.42935 11.6486 7.99137 11.6486 7.45109V5.93479C11.6486 5.39451 11.2932 4.95652 10.8549 4.95652H5.38115C4.9428 4.95652 4.58743 5.39451 4.58743 5.93479V18.0652C4.58743 18.6056 4.9428 19.0435 5.38115 19.0435H10.8549C11.2932 19.0435 11.6486 18.6056 11.6486 18.0652V16.5489C11.6486 16.0087 12.0039 15.5707 12.4423 15.5707C12.8807 15.5707 13.236 16.0087 13.236 16.5489V18.0652C13.236 19.686 12.1699 21 10.8549 21H5.38115C4.06609 21 3 19.686 3 18.0652V5.93479Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9.30048 12C9.30048 12.5402 9.65582 12.9782 10.0942 12.9782L19.5114 12.9782L15.9111 17.8587C15.6162 18.2585 15.6401 18.8771 15.9644 19.2406C16.2888 19.6042 16.7908 19.5746 17.0857 19.1749L21.7055 12.9124C22.0994 12.3785 22.0979 11.5626 21.7023 11.0308L17.0834 4.82203C16.7872 4.4238 16.2851 4.39697 15.962 4.7621C15.6389 5.12723 15.6171 5.74606 15.9134 6.14429L19.5418 11.0217L10.0942 11.0217C9.65582 11.0217 9.30048 11.4597 9.30048 12Z" fill="currentColor" />
    </SvgIcon>
  )
}

export default SignInIcon
