import React from "react"
import { SvgIcon } from "@mui/material"

function TrustIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.50001 21H18.5M12 21V7M12 7C12.5304 7 13.0392 6.78929 13.4142 6.41421C13.7893 6.03914 14 5.53043 14 5C14 4.46957 13.7893 3.96086 13.4142 3.58579C13.0392 3.21071 12.5304 3 12 3C11.4696 3 10.9609 3.21071 10.5858 3.58579C10.2107 3.96086 10 4.46957 10 5C10 5.53043 10.2107 6.03914 10.5858 6.41421C10.9609 6.78929 11.4696 7 12 7ZM14 5.2C17.073 5.861 16.467 8 19 8M5.00001 8C8.35901 8 7.19201 5.885 10.012 5.207M7.00001 9.556V7.75M7.00001 9.556L5.05001 13.949C4.98257 14.1268 4.98302 14.3232 5.05128 14.5007C5.11954 14.6781 5.25082 14.8242 5.42001 14.911C5.53178 14.9693 5.65595 14.9998 5.78201 15H8.21801C8.34423 15 8.46857 14.9695 8.58051 14.9112C8.69244 14.8529 8.78866 14.7684 8.86101 14.665C8.93289 14.5619 8.97863 14.4428 8.99431 14.3181C9.00998 14.1934 8.99513 14.0667 8.95101 13.949L7.00001 9.556ZM17 9.556V7.313M17 9.556L15.05 13.949C14.9826 14.1268 14.983 14.3232 15.0513 14.5007C15.1195 14.6781 15.2508 14.8242 15.42 14.911C15.5318 14.9693 15.656 14.9998 15.782 15H18.218C18.3442 15 18.4686 14.9695 18.5805 14.9112C18.6924 14.8529 18.7887 14.7684 18.861 14.665C18.933 14.5619 18.9788 14.4429 18.9944 14.3181C19.0101 14.1934 18.9952 14.0667 18.951 13.949L17 9.556Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default TrustIcon