
import React from "react"
import { SvgIcon, type SvgIconProps } from "@mui/material"

function MyGoldIcon(props: SvgIconProps) {
    return (
        // <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        //     <rect width="41" height="41" rx="10" fill="#EAA22B" />
        //     <path d="M29.4062 15.5312V15.0312H28.9062H28.5C28.4171 15.0312 28.3376 14.9983 28.279 14.9397C28.2204 14.8811 28.1875 14.8016 28.1875 14.7188C28.1875 14.6359 28.2204 14.5564 28.279 14.4978C28.3376 14.4392 28.4171 14.4062 28.5 14.4062H28.9062H29.4062V13.9062V13.5C29.4062 13.4171 29.4392 13.3376 29.4978 13.279C29.5564 13.2204 29.6359 13.1875 29.7188 13.1875C29.8016 13.1875 29.8811 13.2204 29.9397 13.279C29.9983 13.3376 30.0312 13.4171 30.0312 13.5V13.9062V14.4062H30.5312H30.9375C31.0204 14.4062 31.0999 14.4392 31.1585 14.4978C31.2171 14.5564 31.25 14.6359 31.25 14.7188C31.25 14.8016 31.2171 14.8811 31.1585 14.9397C31.0999 14.9983 31.0204 15.0312 30.9375 15.0312H30.5312H30.0312V15.5312V15.9375C30.0312 16.0204 29.9983 16.0999 29.9397 16.1585C29.8811 16.2171 29.8016 16.25 29.7188 16.25C29.6359 16.25 29.5564 16.2171 29.4978 16.1585C29.4392 16.0999 29.4062 16.0204 29.4062 15.9375V15.5312Z" stroke="white" />
        //     <path d="M20.8635 13.0115C20.4715 12.6195 19.9888 12.3304 19.4584 12.1697C19.3984 12.1485 19.3462 12.1095 19.309 12.0578C19.2706 12.0045 19.25 11.9406 19.25 11.875C19.25 11.8094 19.2706 11.7455 19.309 11.6922C19.3462 11.6405 19.3984 11.6015 19.4584 11.5803C19.9888 11.4196 20.4715 11.1305 20.8635 10.7385C21.2555 10.3465 21.5446 9.86383 21.7053 9.33336C21.7265 9.27337 21.7655 9.22123 21.8172 9.18398C21.8705 9.14563 21.9344 9.125 22 9.125C22.0656 9.125 22.1295 9.14563 22.1828 9.18398C22.2345 9.22123 22.2735 9.27337 22.2947 9.33336C22.4554 9.86383 22.7445 10.3465 23.1365 10.7385C23.5285 11.1305 24.0112 11.4196 24.5416 11.5803C24.6016 11.6015 24.6538 11.6405 24.691 11.6922C24.7294 11.7455 24.75 11.8094 24.75 11.875C24.75 11.9406 24.7294 12.0045 24.691 12.0578C24.6538 12.1095 24.6016 12.1485 24.5416 12.1697C24.0112 12.3304 23.5285 12.6195 23.1365 13.0115C22.7445 13.4035 22.4554 13.8862 22.2947 14.4166C22.2735 14.4766 22.2345 14.5288 22.1828 14.566C22.1295 14.6044 22.0656 14.625 22 14.625C21.9344 14.625 21.8705 14.6044 21.8172 14.566C21.7655 14.5288 21.7265 14.4766 21.7053 14.4166C21.5446 13.8862 21.2555 13.4035 20.8635 13.0115Z" stroke="white" />
        //     <path d="M13.8567 15.1433C13.606 14.8927 13.297 14.7084 12.9575 14.6069C12.8978 14.5856 12.846 14.5467 12.809 14.4953C12.7706 14.442 12.75 14.3781 12.75 14.3125C12.75 14.2469 12.7706 14.183 12.809 14.1297C12.846 14.0783 12.8978 14.0394 12.9575 14.0181C13.297 13.9166 13.606 13.7323 13.8567 13.4817C14.1073 13.231 14.2916 12.922 14.3931 12.5825C14.4144 12.5228 14.4533 12.471 14.5047 12.434C14.558 12.3956 14.6219 12.375 14.6875 12.375C14.7531 12.375 14.817 12.3956 14.8703 12.434C14.9217 12.471 14.9606 12.5228 14.9819 12.5825C15.0834 12.922 15.2677 13.231 15.5183 13.4817C15.769 13.7323 16.078 13.9166 16.4175 14.0181C16.4771 14.0394 16.5289 14.0783 16.566 14.1297C16.6044 14.183 16.625 14.2469 16.625 14.3125C16.625 14.3781 16.6044 14.442 16.566 14.4953C16.5289 14.5467 16.4772 14.5856 16.4175 14.6069C16.078 14.7084 15.769 14.8927 15.5183 15.1433C15.2677 15.394 15.0834 15.703 14.9819 16.0425C14.9606 16.1021 14.9217 16.1539 14.8703 16.191C14.817 16.2294 14.7531 16.25 14.6875 16.25C14.6219 16.25 14.558 16.2294 14.5047 16.191C14.4533 16.1539 14.4144 16.1022 14.3931 16.0425C14.2916 15.703 14.1073 15.394 13.8567 15.1433Z" stroke="white" />
        //     <path d="M32.0504 28.8515L32.0503 28.8515L30.4254 23.1641C30.4067 23.0988 30.3673 23.0414 30.3131 23.0005C30.2589 22.9596 30.1928 22.9375 30.1249 22.9375H29.1128H28.7356L28.632 22.5749L27.1754 17.4766C27.1567 17.4113 27.1173 17.3539 27.0631 17.313C27.0089 17.2721 26.9428 17.25 26.8749 17.25H17.1249C17.057 17.25 16.991 17.2721 16.9368 17.313C16.8826 17.3539 16.8432 17.4113 16.8245 17.4765C16.8245 17.4765 16.8245 17.4765 16.8245 17.4766L15.3679 22.5749L15.2642 22.9375H14.8871H13.8749C13.807 22.9375 13.741 22.9596 13.6868 23.0005C13.6326 23.0414 13.5932 23.0988 13.5745 23.1641L11.9495 28.8515L11.9495 28.8515C11.9362 28.8981 11.9339 28.947 11.9428 28.9946C11.9516 29.0421 11.9714 29.087 12.0005 29.1256C12.0296 29.1643 12.0673 29.1956 12.1106 29.2172C12.1539 29.2387 12.2016 29.25 12.2499 29.25L32.0504 28.8515ZM32.0504 28.8515C32.0636 28.8981 32.066 28.947 32.0571 28.9946C32.0483 29.0421 32.0285 29.087 31.9994 29.1256C31.9702 29.1643 31.9325 29.1956 31.8893 29.2172C31.846 29.2387 31.7983 29.25 31.7499 29.25C31.7499 29.25 31.7499 29.25 31.7499 29.25M32.0504 28.8515L31.7499 29.25M31.7499 29.25H12.25H31.7499ZM17.7379 17.875H17.3607L17.2571 18.2376L16.0963 22.3001L15.9142 22.9375H16.5771H20.9228H21.5857L21.4036 22.3001L20.2428 18.2376L20.1391 17.875H19.762H17.7379ZM12.8463 27.9876L12.6642 28.625H13.3271H17.6728H18.3357L18.1536 27.9876L16.9928 23.9251L16.8891 23.5625H16.512H14.4879H14.1107L14.0071 23.9251L12.8463 27.9876ZM19.3463 27.9876L19.1642 28.625H19.8271H24.1728H24.8357L24.6536 27.9876L23.4928 23.9251L23.3891 23.5625H23.012H20.9879H20.6107L20.5071 23.9251L19.3463 27.9876Z" stroke="white" />
        // </svg>
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.4062 8.53125V8.03125H19.9062H19.5C19.4171 8.03125 19.3376 7.99833 19.279 7.93972C19.2204 7.88112 19.1875 7.80163 19.1875 7.71875C19.1875 7.63587 19.2204 7.55638 19.279 7.49778C19.3376 7.43917 19.4171 7.40625 19.5 7.40625H19.9062H20.4062V6.90625V6.5C20.4062 6.41712 20.4392 6.33763 20.4978 6.27903C20.5564 6.22042 20.6359 6.1875 20.7188 6.1875C20.8016 6.1875 20.8811 6.22042 20.9397 6.27903C20.9983 6.33763 21.0312 6.41712 21.0312 6.5V6.90625V7.40625H21.5312H21.9375C22.0204 7.40625 22.0999 7.43917 22.1585 7.49778C22.2171 7.55638 22.25 7.63587 22.25 7.71875C22.25 7.80163 22.2171 7.88112 22.1585 7.93972C22.0999 7.99833 22.0204 8.03125 21.9375 8.03125H21.5312H21.0312V8.53125V8.9375C21.0312 9.02038 20.9983 9.09987 20.9397 9.15847C20.8811 9.21708 20.8016 9.25 20.7188 9.25C20.6359 9.25 20.5564 9.21708 20.4978 9.15847C20.4392 9.09987 20.4062 9.02038 20.4062 8.9375V8.53125Z" stroke="#EAA22B" />
            <path d="M13.1828 7.56601L13.4092 7.88034L13.1828 7.56601C13.1295 7.60436 13.0656 7.625 13 7.625C12.9344 7.625 12.8705 7.60436 12.8172 7.56601C12.7655 7.52876 12.7265 7.47663 12.7053 7.41664C12.5446 6.88617 12.2555 6.4035 11.8635 6.01151C11.4715 5.61951 10.9888 5.33038 10.4584 5.16972C10.3984 5.1485 10.3462 5.10945 10.309 5.05775C10.2706 5.00453 10.25 4.9406 10.25 4.875C10.25 4.8094 10.2706 4.74547 10.309 4.69224C10.3462 4.64054 10.3984 4.6015 10.4584 4.58028C10.9888 4.41961 11.4715 4.13049 11.8635 3.73849C12.2555 3.34649 12.5446 2.86383 12.7053 2.33336C12.7265 2.27337 12.7655 2.22123 12.8172 2.18398C12.8705 2.14563 12.9344 2.125 13 2.125C13.0656 2.125 13.1295 2.14563 13.1828 2.18398C13.2345 2.22123 13.2735 2.27337 13.2947 2.33336C13.4554 2.86383 13.7445 3.34649 14.1365 3.73849C14.5285 4.13049 15.0112 4.41961 15.5416 4.58028C15.6016 4.6015 15.6538 4.64054 15.691 4.69224L16.0967 4.39995L15.691 4.69224C15.7294 4.74547 15.75 4.8094 15.75 4.875C15.75 4.9406 15.7294 5.00453 15.691 5.05775L16.0967 5.35005L15.691 5.05775C15.6538 5.10945 15.6016 5.1485 15.5416 5.16972C15.0112 5.33038 14.5285 5.61951 14.1365 6.01151C13.7445 6.40351 13.4554 6.88617 13.2947 7.41664C13.2735 7.47663 13.2345 7.52876 13.1828 7.56601Z" stroke="#EAA22B" />
            <path d="M4.85667 8.14333C4.60601 7.89267 4.29698 7.70836 3.95746 7.6069C3.89785 7.58561 3.84605 7.5467 3.80898 7.49525C3.77063 7.44203 3.75 7.3781 3.75 7.3125C3.75 7.2469 3.77063 7.18296 3.80898 7.12974C3.84605 7.0783 3.89785 7.03939 3.95746 7.01809C4.29698 6.91664 4.60601 6.73232 4.85667 6.48167C5.10732 6.23101 5.29164 5.92198 5.39309 5.58246C5.41439 5.52285 5.4533 5.47105 5.50474 5.43398C5.55797 5.39563 5.6219 5.375 5.6875 5.375C5.7531 5.375 5.81703 5.39563 5.87025 5.43398C5.9217 5.47105 5.96061 5.52285 5.9819 5.58246C6.08336 5.92198 6.26767 6.23101 6.51833 6.48167C6.76899 6.73232 7.07802 6.91664 7.41754 7.01809C7.47715 7.03939 7.52895 7.0783 7.56601 7.12974C7.60436 7.18296 7.625 7.2469 7.625 7.3125C7.625 7.3781 7.60436 7.44203 7.56602 7.49525C7.52895 7.5467 7.47715 7.58561 7.41754 7.6069C7.07802 7.70836 6.76899 7.89267 6.51833 8.14333C6.26767 8.39399 6.08336 8.70302 5.9819 9.04254C5.96061 9.10215 5.9217 9.15395 5.87025 9.19101C5.81703 9.22936 5.7531 9.25 5.6875 9.25C5.6219 9.25 5.55797 9.22936 5.50474 9.19101C5.4533 9.15395 5.41439 9.10215 5.39309 9.04253C5.29164 8.70301 5.10732 8.39399 4.85667 8.14333Z" stroke="#EAA22B" />
            <path d="M23.0504 21.8515L23.0503 21.8515L21.4254 16.1641C21.4067 16.0988 21.3673 16.0414 21.3131 16.0005C21.2589 15.9596 21.1928 15.9375 21.1249 15.9375H20.1128H19.7356L19.632 15.5749L18.1754 10.4766C18.1754 10.4765 18.1753 10.4765 18.1753 10.4765C18.1567 10.4113 18.1173 10.3539 18.0631 10.313C18.0089 10.2721 17.9428 10.25 17.8749 10.25H8.12494C8.05705 10.25 7.991 10.2721 7.9368 10.313C7.88261 10.3539 7.84321 10.4113 7.82454 10.4765C7.82454 10.4765 7.82453 10.4765 7.82452 10.4766L6.36786 15.5749L6.26425 15.9375H5.8871H4.87494C4.80705 15.9375 4.741 15.9596 4.6868 16.0005C4.63261 16.0414 4.59321 16.0988 4.57454 16.164C4.57454 16.164 4.57453 16.164 4.57452 16.1641L2.94954 21.8515L2.94953 21.8515C2.93624 21.8981 2.93392 21.947 2.94277 21.9946C2.95162 22.0421 2.97138 22.087 3.00051 22.1256C3.02964 22.1643 3.06734 22.1956 3.11063 22.2172C3.1539 22.2387 3.20159 22.25 3.24994 22.25L23.0504 21.8515ZM23.0504 21.8515C23.0636 21.8981 23.066 21.947 23.0571 21.9946C23.0483 22.0421 23.0285 22.087 22.9994 22.1256C22.9702 22.1643 22.9325 22.1956 22.8893 22.2172C22.846 22.2387 22.7983 22.25 22.7499 22.25C22.7499 22.25 22.7499 22.25 22.7499 22.25M23.0504 21.8515L22.7499 22.25M22.7499 22.25H3.25002H22.7499ZM8.73788 10.875H8.36074L8.25712 11.2376L7.0963 15.3001L6.91417 15.9375H7.57706H11.9228H12.5857L12.4036 15.3001L11.2428 11.2376L11.1391 10.875H10.762H8.73788ZM3.8463 20.9876L3.66417 21.625H4.32706H8.67283H9.33571L9.15359 20.9876L7.99276 16.9251L7.88914 16.5625H7.512H5.48788H5.11074L5.00712 16.9251L3.8463 20.9876ZM10.3463 20.9876L10.1642 21.625H10.8271H15.1728H15.8357L15.6536 20.9876L14.4928 16.9251L14.3891 16.5625H14.012H11.9879H11.6107L11.5071 16.9251L10.3463 20.9876Z" stroke="#EAA22B" />
        </svg>

    )
}

export default MyGoldIcon