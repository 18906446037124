
import React from "react"
import { SvgIcon, type SvgIconProps } from "@mui/material"

function PhoneCall1Icon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 0C4.92525 0 0 4.92525 0 11C0 17.0748 4.92525 22 11 22C17.0748 22 22 17.0748 22 11C22 4.92525 17.0748 0 11 0ZM16.5 17.1875C10.0554 17.1875 4.8125 11.9446 4.8125 5.5C4.8125 5.1205 5.1205 4.8125 5.5 4.8125H8.03825C8.41775 4.8125 8.72575 5.1205 8.72575 5.5C8.72575 6.688 8.98425 7.81963 9.49438 8.86188C9.53443 8.94313 9.55786 9.03157 9.56329 9.122C9.56872 9.21243 9.55604 9.30303 9.526 9.3885C9.49661 9.47422 9.45047 9.55325 9.39028 9.62099C9.33009 9.68874 9.25704 9.74385 9.17537 9.78312L8.14962 10.2781C9.0035 11.7645 10.2355 12.9979 11.7205 13.849L12.2155 12.8246C12.2952 12.6596 12.4369 12.5345 12.6101 12.474C12.7834 12.4149 12.9731 12.4259 13.1368 12.5056C14.1818 13.0157 15.312 13.2743 16.4986 13.2743C16.8781 13.2743 17.1861 13.5823 17.1861 13.9618V16.5H17.1875C17.1875 16.8795 16.8795 17.1875 16.5 17.1875Z" fill="white" />
            </svg>
        </SvgIcon>
    )
}

export default PhoneCall1Icon
