import React from "react";

function OrdersIcon() {
  return (
    // <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <rect width="41" height="41" rx="10" fill="#3491FA" />
    //   <path d="M31 14.682C31 14.4029 30.838 14.1514 30.5901 14.0453L21.2513 10.0516C21.0903 9.98279 20.9097 9.98279 20.7487 10.0516L11.4099 14.0453C11.162 14.1514 11 14.4029 11 14.682V27.318C11 27.5971 11.1619 27.8486 11.4099 27.9547L20.7487 31.9484C20.908 32.0165 21.087 32.0179 21.2504 31.9483C21.2584 31.9456 20.9048 32.0965 30.5901 27.9547C30.8381 27.8486 31 27.5971 31 27.318V14.682ZM21 17.9895L17.559 16.518L24.9954 13.141L28.6665 14.7109L21 17.9895ZM12.3224 15.7667L15.2912 17.0363V20.6182C15.2912 20.9983 15.5872 21.3065 15.9524 21.3065C16.3175 21.3065 16.6135 20.9983 16.6135 20.6182V17.6018L20.3388 19.1949V30.2848L12.3224 26.8566V15.7667ZM21 11.4324L23.2921 12.4126L15.8557 15.7895L13.3335 14.7109L21 11.4324ZM21.6612 19.1949L29.6776 15.7667V26.8566L21.6612 30.2848V19.1949Z" fill="white" />
    // </svg>
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 4.68199C20 4.40289 19.838 4.15141 19.5901 4.04533L10.2513 0.0516216C10.0903 -0.0172072 9.90975 -0.0172072 9.74875 0.0516216L0.409938 4.04533C0.16195 4.15137 0 4.40289 0 4.68199V17.318C0 17.5971 0.161909 17.8486 0.409938 17.9547L9.74875 21.9484C9.90801 22.0165 10.087 22.0179 10.2504 21.9483C10.2584 21.9456 9.90483 22.0965 19.5901 17.9547C19.8381 17.8486 20 17.5971 20 17.318V4.68199ZM10 7.98947L6.559 6.51796L13.9954 3.141L17.6665 4.71094L10 7.98947ZM1.32238 5.76673L4.29116 7.03632V10.6182C4.29116 10.9983 4.58721 11.3065 4.95235 11.3065C5.31749 11.3065 5.61354 10.9983 5.61354 10.6182V7.60184L9.33881 9.19492V20.2848L1.32238 16.8566V5.76673ZM10 1.43237L12.2921 2.41258L4.85574 5.78953L2.3335 4.7109L10 1.43237ZM10.6612 9.19492L18.6776 5.76673V16.8566L10.6612 20.2848V9.19492Z" fill="#EAA22B" />
    </svg>
  );
}

export default OrdersIcon;
