import React from "react"
import { SvgIcon, type SvgIconProps } from "@mui/material"

function AddressesIcon(props: SvgIconProps) {
  return (
    // <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <rect width="41" height="41" rx="10" fill="#3491FA" />
    //   <path d="M26.7695 25.1116L27.1674 25.4143L27.1674 25.4143L26.7695 25.1116ZM21.2285 30.2524L20.9567 29.8328L20.9548 29.834L21.2285 30.2524ZM19.7704 30.2524L19.4997 30.6727L19.5006 30.6733L19.7704 30.2524ZM14.2305 25.1116L14.6297 24.8105L14.6283 24.8087L14.2305 25.1116ZM20.4934 10C15.5795 10 11.5 13.9302 11.5 18.8178H12.5C12.5 14.5056 16.1083 11 20.4934 11V10ZM29.5 18.8178C29.5 13.9297 25.42 10 20.4934 10V11C24.8922 11 28.5 14.5061 28.5 18.8178H29.5ZM27.1674 25.4143C28.6106 23.5171 29.5 21.2612 29.5 18.8178H28.5C28.5 21.0102 27.7034 23.0581 26.3716 24.8089L27.1674 25.4143ZM21.5004 30.672C23.6728 29.2645 25.602 27.4719 27.1674 25.4143L26.3716 24.8088C24.8756 26.7751 23.0316 28.4884 20.9567 29.8328L21.5004 30.672ZM19.5006 30.6733C19.8244 30.881 20.1625 31.0044 20.5236 30.9999C20.8838 30.9953 21.2069 30.8641 21.5023 30.6708L20.9548 29.834C20.7641 29.9588 20.6249 29.9985 20.5109 30C20.3978 30.0014 20.2499 29.9659 20.0403 29.8315L19.5006 30.6733ZM13.8313 25.4127C15.3844 27.4717 17.3146 29.265 19.4997 30.6727L20.0412 29.8321C17.9549 28.4879 16.1117 26.7753 14.6297 24.8105L13.8313 25.4127ZM11.5 18.8178C11.5 21.2611 12.3883 23.5171 13.8327 25.4144L14.6283 24.8087C13.2957 23.0582 12.5 21.0103 12.5 18.8178H11.5ZM20.4934 21.3297C19.217 21.3297 18.1942 20.3308 18.1942 19.0768H17.1942C17.1942 20.9046 18.6864 22.3297 20.4934 22.3297V21.3297ZM22.8058 19.0768C22.8058 20.328 21.7739 21.3297 20.4934 21.3297V22.3297C22.2986 22.3297 23.8058 20.9075 23.8058 19.0768H22.8058ZM20.4934 16.7768C21.7614 16.7768 22.8058 17.8252 22.8058 19.0768H23.8058C23.8058 17.2704 22.3111 15.7768 20.4934 15.7768V16.7768ZM18.1942 19.0768C18.1942 17.8223 19.2295 16.7768 20.4934 16.7768V15.7768C18.6739 15.7768 17.1942 17.2733 17.1942 19.0768H18.1942Z" fill="white" />
    // </svg>
    <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.7695 15.1116L16.1674 15.4143L16.1674 15.4143L15.7695 15.1116ZM10.2285 20.2524L9.95666 19.8328L9.9548 19.834L10.2285 20.2524ZM8.77045 20.2524L8.49965 20.6727L8.50059 20.6733L8.77045 20.2524ZM3.2305 15.1116L3.62968 14.8105L3.62834 14.8087L3.2305 15.1116ZM9.49345 0C4.57948 0 0.5 3.93015 0.5 8.81776H1.5C1.5 4.50563 5.10828 1 9.49345 1V0ZM18.5 8.81776C18.5 3.92966 14.42 0 9.49345 0V1C13.8922 1 17.5 4.50612 17.5 8.81776H18.5ZM16.1674 15.4143C17.6106 13.5171 18.5 11.2612 18.5 8.81776H17.5C17.5 11.0102 16.7034 13.0581 15.3716 14.8089L16.1674 15.4143ZM10.5004 20.672C12.6728 19.2645 14.602 17.4719 16.1674 15.4143L15.3716 14.8088C13.8756 16.7751 12.0316 18.4884 9.95666 19.8328L10.5004 20.672ZM8.50059 20.6733C8.82443 20.881 9.16251 21.0044 9.52359 20.9999C9.88378 20.9953 10.2069 20.8641 10.5023 20.6708L9.9548 19.834C9.76411 19.9588 9.62494 19.9985 9.51094 20C9.39784 20.0014 9.24989 19.9659 9.04031 19.8315L8.50059 20.6733ZM2.83132 15.4127C4.38445 17.4717 6.31459 19.265 8.49965 20.6727L9.04125 19.8321C6.95488 18.4879 5.11173 16.7753 3.62968 14.8105L2.83132 15.4127ZM0.5 8.81776C0.5 11.2611 1.38827 13.5171 2.83266 15.4144L3.62834 14.8087C2.2957 13.0582 1.5 11.0103 1.5 8.81776H0.5ZM9.49345 11.3297C8.21695 11.3297 7.19423 10.3308 7.19423 9.07675H6.19423C6.19423 10.9046 7.68636 12.3297 9.49345 12.3297V11.3297ZM11.8058 9.07675C11.8058 10.328 10.7739 11.3297 9.49345 11.3297V12.3297C11.2986 12.3297 12.8058 10.9075 12.8058 9.07675H11.8058ZM9.49345 6.77683C10.7614 6.77683 11.8058 7.82521 11.8058 9.07675H12.8058C12.8058 7.27038 11.3111 5.77683 9.49345 5.77683V6.77683ZM7.19423 9.07675C7.19423 7.82228 8.22945 6.77683 9.49345 6.77683V5.77683C7.67387 5.77683 6.19423 7.27331 6.19423 9.07675H7.19423Z" fill="#EAA22B" />
    </svg>

  )
}

export default AddressesIcon