import React from 'react'

function MapIcon() {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="0.5" width="31" height="31" rx="15.5" stroke="white" strokeOpacity="0.8" />
      <g clipPath="url(#clip0_455_64660)">
        <path d="M20.7348 9.36956C19.9663 8.74035 19.0678 8.28952 18.104 8.0496C17.1402 7.80968 16.1352 7.78665 15.1615 7.98216C13.9094 8.23885 12.7575 8.85005 11.843 9.74293C10.9286 10.6358 10.29 11.7728 10.0035 13.0183C9.7169 14.2639 9.79444 15.5656 10.2268 16.7683C10.6592 17.971 11.4282 19.0241 12.4422 19.8022C13.5999 20.6495 14.5856 21.7096 15.3466 22.9259L15.8652 23.7881C15.931 23.8976 16.0241 23.9882 16.1353 24.0511C16.2466 24.114 16.3722 24.1471 16.5 24.1471C16.6278 24.1471 16.7534 24.114 16.8646 24.0511C16.9759 23.9882 17.0689 23.8976 17.1348 23.7881L17.6318 22.9599C18.2945 21.795 19.1981 20.7849 20.2822 19.997C21.1323 19.4121 21.8348 18.6377 22.3344 17.7348C22.8339 16.832 23.1169 15.8254 23.1608 14.7945C23.2048 13.7636 23.0086 12.7366 22.5877 11.7945C22.1668 10.8524 21.5327 10.021 20.7355 9.36586L20.7348 9.36956ZM16.4992 17.4814C15.9132 17.4814 15.3404 17.3076 14.8531 16.9821C14.3658 16.6565 13.9861 16.1937 13.7618 15.6523C13.5376 15.1109 13.4789 14.5152 13.5932 13.9404C13.7075 13.3656 13.9897 12.8377 14.4041 12.4233C14.8185 12.0089 15.3464 11.7267 15.9212 11.6124C16.4959 11.4981 17.0917 11.5568 17.6331 11.781C18.1745 12.0053 18.6373 12.3851 18.9629 12.8723C19.2884 13.3596 19.4622 13.9324 19.4622 14.5185C19.4622 15.3043 19.15 16.0579 18.5944 16.6136C18.0387 17.1692 17.2851 17.4814 16.4992 17.4814Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_455_64660">
          <rect width="17.7778" height="17.7778" fill="white" transform="translate(7.61108 7.11108)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MapIcon