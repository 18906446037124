import React from 'react'

function CashIcon() {
  return (
    <svg width="47" height="48" viewBox="0 0 47 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.5" width="47" height="47" rx="23.5" fill="#EAA22B" fillOpacity="0.1" />
      <path d="M25.1738 26.3832C28.6975 26.3832 31.5643 23.4935 31.5643 19.9416C31.5643 16.3898 28.6975 13.5 25.1738 13.5C21.65 13.5 18.7832 16.3897 18.7832 19.9416C18.7832 23.4935 21.65 26.3832 25.1738 26.3832ZM23.1203 21.9517C23.3062 21.6676 23.6873 21.5878 23.9714 21.7738C24.3855 22.0446 24.5417 22.0696 25.0881 22.0658C25.6214 22.0622 25.9308 21.6648 25.9926 21.2969C26.0227 21.118 26.0342 20.681 25.4927 20.4896C24.8576 20.2651 24.2075 20.0084 23.7553 19.6537C23.3031 19.299 23.096 18.6867 23.2149 18.0558C23.3438 17.3719 23.8209 16.8274 24.4601 16.6349C24.4659 16.6332 24.4715 16.6318 24.4773 16.6301V16.397C24.4773 16.0574 24.7526 15.7821 25.0922 15.7821C25.4317 15.7821 25.707 16.0574 25.707 16.397V16.5914C26.1246 16.691 26.4163 16.8821 26.5347 16.9706C26.8067 17.174 26.8624 17.5592 26.659 17.8313C26.4557 18.1033 26.0705 18.1589 25.7984 17.9555C25.6724 17.8613 25.3242 17.6588 24.8147 17.8124C24.5171 17.902 24.4399 18.1957 24.4233 18.2836C24.3908 18.4562 24.4273 18.618 24.5142 18.6861C24.8277 18.9319 25.392 19.1497 25.9026 19.3301C26.8441 19.6629 27.3676 20.5352 27.2053 21.5007C27.1257 21.9745 26.8874 22.414 26.5342 22.7384C26.2937 22.9595 26.0137 23.117 25.707 23.207V23.4862C25.707 23.8258 25.4317 24.1011 25.0922 24.1011C24.7526 24.1011 24.4773 23.8258 24.4773 23.4862V23.2683C24.0797 23.2201 23.746 23.0958 23.2982 22.8029C23.0141 22.617 22.9344 22.2359 23.1203 21.9517Z" fill="#EAA22B" />
      <path d="M15.3313 28.4492H13.9293C13.5898 28.4492 13.3145 28.7245 13.3145 29.0641V33.8842C13.3145 34.2238 13.5898 34.4991 13.9293 34.4991H15.3313L15.3313 28.4492Z" fill="#EAA22B" />
      <path d="M33.5063 28.4063C32.3539 27.2539 30.4787 27.2538 29.3263 28.4063L27.4844 30.2482L26.7294 31.0031C26.4243 31.3082 26.0105 31.4797 25.579 31.4797H21.9081C21.5766 31.4797 21.2913 31.2248 21.2756 30.8937C21.259 30.5401 21.5407 30.2482 21.8907 30.2482H25.6219C26.3722 30.2482 27.0229 29.7141 27.1518 28.975C27.1815 28.8053 27.1969 28.6308 27.1969 28.4528C27.1969 28.1126 26.9213 27.8366 26.5812 27.8366H24.5367C23.8685 27.8366 23.2269 27.5335 22.5475 27.2125C21.835 26.8759 21.0982 26.5279 20.2367 26.4706C19.4832 26.4204 18.728 26.5029 17.9919 26.7154C17.2037 26.943 16.6372 27.6432 16.5685 28.4516C16.5659 28.4514 16.5632 28.4514 16.5605 28.4512V34.4971L27.1538 34.5C27.8821 34.5 28.5669 34.2163 29.082 33.7013L33.5062 29.2771C33.7468 29.0367 33.7468 28.6468 33.5063 28.4063Z" fill="#EAA22B" />
    </svg>
  )
}

export default CashIcon